* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --base00: #e7407d;
  --base01: #404040;
  --base02: #9f9f9f;
  --border: #e8e8e8;
  --black: #000000;
  --white: #ffffff;
  --primarygradient: linear-gradient(90deg, #e7407d 0%, #ef805f 100%);
}

body {
  padding-right: 0 !important;
  font-family: Graphik, sans-serif;
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  background-color: var(--pureblack);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Termina Test", sans-serif;
}

ol,
ul {
  padding-left: 0;
  margin-bottom: 0;
}

img {
  max-width: 100%;
}

p {
  margin: 0;
}

.piebar {
  width: 250px;
  height: 250px;
  margin: 0 auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.pie-parent svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.countNumber {
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  grid-template-rows: 60% 40%;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #333333;
}

/* Firefox */
* {
  scrollbar-color: #333333 rgb(255 255 255 / 24%);
  /* Change the first color to the thumb color and the second one to the track color */
}

*::-webkit-scrollbar-track {
  background-color: rgb(255 255 255 / 24%);
  /* Change this to your desired color */
}

.countNumber h3 {
  font-family: "Exo", sans-serif;
  background: linear-gradient(90deg, #535353 9.29%, #a1a1a1 89.91%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px;
  word-spacing: -10px;
  margin-bottom: 0px;
}

.cursor-pointer {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.arrow-dropdown {
  appearance: auto;
  padding: 0 0 16px 0 !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.history-job table.table {
  display: table;
}

.form-check-input:checked {
  background: var(--base);
  border-color: var(--base);
}

/* Colors Variables */
:root {
  --base: #ff4012;
  --pureblack: #181717;
  --white: #ffffff;
  --offwhite: rgba(255, 255, 255, 0.8);
  --gray: #2f2f31;
  --gray-gradient: linear-gradient(180deg, #2f2f31 0%, #1d1e20 100%);
  --lightgray-gradient: linear-gradient(270deg, #2f2f31 0%, #1d1e20 100%);
  --text-gradient: linear-gradient(180deg, #ff6b00 0%, #ac3700 156.15%);
  --number-gradient: linear-gradient(90deg, #535353 9.29%, #a1a1a1 89.91%);
  --input-bg: #252628;
  --input-border: #191a1d;
  --input-placeholder: #858585;
}

ul li {
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--white);
}

button {
  transition: 0.1s ease;
  cursor: pointer;
}

.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid var(--white);
  border-radius: 0;
  padding: 0 0 16px 0;
  color: var(--white) !important;
  font-family: Graphik, sans-serif, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.form-control::placeholder {
  color: var(--offwhite);
}

.form-control:focus,
.form-control:focus-visible {
  background: transparent !important;
  box-shadow: none !important;
  border-color: var(--white);
}

.container {
  max-width: 1370px;
}

.navbar-expand-lg .mobile-togglemenu.navbar-collapse {
  display: none !important;
}

.privacy-banner {
  padding: 160px 0 40px;
}

/* Header css  */
.MainHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.3s ease;
  z-index: 999;
}

.MainHeader.sticky-header {
  background: #161414;
}

.MainHeader.sticky-header .navbar-primary {
  padding: 20px 0;
}

.MainHeader .navbar-primary {
  position: relative;
  padding: 30px 0;
  transition: 0.3s ease;
}

.navbar-brand {
  padding: 0;
  margin: 0;
}

.userrole-modal .modal-dialog {
  max-width: 770px;
}

.userrole-modal .modal-dialog .modal-content {
  background-image: url("../../public/images/pattern.webp");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.userrole-modal .modal-dialog .modal-content .modal-title.h4 {
  text-align: center;
  display: block !important;
}

.userrole-modal .modal-dialog .modal-content .modal-header {
  display: none;
  border: none;
}

.userrole-modal .modal-dialog .modal-content .userrole-modalbody {
  padding: 40px;
  text-align: center;
}

.userrole-modal .modal-dialog .modal-content .userrole-modalbody h2 {
  color: #ffffff;
  text-align: center;
  font-family: "Termina Test", sans-serif, sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: 46px;
  /* 125.714% */
  letter-spacing: 0.2px;
  margin-bottom: 10px;
}

.userrole-modal .modal-dialog .modal-content .userrole-modalbody p {
  color: #ffffff;
  text-align: center;

  /* Sub Heading */
  font-family: Graphik, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  /* 150% */
  letter-spacing: 0.2px;
  margin-bottom: 40px;
  margin-top: 30px;
}

.MainHeader .navbar-primary .navbar-collapse .rightbar-nav {
  display: none;
}

.rightbar-nav,
.rightbar-nav ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.rightbar-nav {
  column-gap: 36px;
}

.rightbar-nav ul {
  column-gap: 10px;
}

.lang-btn {
  background: transparent;
  box-shadow: none;
  border: none;
  padding: 0;
  color: rgba(255, 255, 255, 0.25);
  text-align: center;
  font-family: "Termina Test", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.lang-btn {
  font-size: 0;
  width: 30px;
}

.rightbar-nav .language-moblie li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slashing {
  color: var(--white);
  font-size: 30px;
}

.lang-btn.active {
  color: var(--white);
}

.MainHeader .navbar-primary .navbar-nav {
  width: 100%;
  justify-content: center;
  column-gap: 40px;
}

.MainHeader .navbar-primary .navbar-nav a {
  text-align: center;
  font-family: "Termina Test", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 160% */
  letter-spacing: 0.2px;
  transition: 0.4s ease;
  color: var(--white);
}

.MainHeader .navbar-primary .navbar-nav a.active,
.MainHeader .navbar-primary .navbar-nav a:hover {
  color: #d24f00;
}

.btn {
  padding: 14px 50px;
  border-radius: 100px;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: var(--white);
  transition: 0.4s ease;
  font-family: "Termina Test", sans-serif;
}

.btnwhite {
  border: 1.171px solid var(--white);
  background: transparent;
}

.btnwhite:hover {
  border: 1.171px solid var(--white);
}

.btnorange {
  border-radius: 100px;
  background: linear-gradient(180deg, #fa6800 0%, #cc4b00 100%);
  box-shadow: 0px 4px 31px 0px rgba(0, 0, 0, 0.15);
  border: none;
}

.btngray {
  border-radius: 100px;
  border: 1.171px solid #2f2f31;
  background: linear-gradient(180deg, #2f2f31 0%, #1d1e20 100%);
  border: none;
}

.home-wrapper .homebar .banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url("../../public/images/pattern.webp");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  padding: 210px 0 100px;
}

.banner::after {
  content: "";
  background: linear-gradient(180deg,
      rgba(24, 23, 23, 0) 8.15%,
      #181717 93.46%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.home-wrapper .homebar .banner .container {
  position: relative;
  z-index: 3;
}

.aboutbanner.loginbaner {
  padding: 100px 0 !important;
  background: #0e0f10;
}

.aboutbanner.loginbaner .auth-form {
  padding: 0 0 0 0;
}

.banner h1 {
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0.2px;
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.banner h1 span {
  display: block;
}

.banner p {
  text-align: center;
  font-family: Graphik, sans-serif;
  font-size: 20px;
  font-style: normal;
  line-height: 30px;
  letter-spacing: 0.2px;
  font-weight: 300;
  max-width: 712px;
  text-align: center;
  margin: 0 auto 70px auto;
}

.btngroup {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
}

.banner .btngroup .btn {
  min-width: 245px;
}

.my-slider {
  width: 100%;
}

.my-slider div {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  background-color: #ddd;
}

.whyapply {
  padding: 0 0 70px 0;
}

.primary-title {
  margin-bottom: 30px;
  text-align: center;
}

.primary-title h4 {
  background: linear-gradient(180deg, #ff6b00 0%, #ac3700 156.15%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.no-drop-cursor {
  cursor: no-drop;
}

.primary-title h2 {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.bar-line {
  max-width: 40px;
  height: 3px;
  background: linear-gradient(180deg, #fb7b5e 0%, #ff461c 100%);
  margin: 0 auto;
}

.whyapply .rec-carousel-item {
  margin: 0 0px;
}

.whyapply .rec-carousel-item .rec-item-wrapper .slidebox {
  padding: 50px 40px !important;
  border-radius: 14.039px;
  background: #2e2e30;
}

.whyapply .rec.rec-swipable {
  column-gap: 10px;
}

.whyapply .rec-carousel {
  width: calc(100% + 100px);
  padding: 0 50px;
  position: relative;
}

.contactus .letstalk-inner {}

.contactus .letstalk-inner h6 {
  font-family: "Termina Test", sans-serif;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #d25029;
  font-weight: bold;
}

.contactus .row {
  align-items: center;
}

.contactus .letstalk-inner h3 {
  margin: 0 0 20px 0 !important;
}

.whyapply .rec-carousel .rec-arrow {
  position: absolute;
  left: 0;
  top: 45%;
  z-index: 950;
  background: url("../../public/images/nexticon.png") !important;
  color: var(--white);
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1 !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 50px !important;
  height: 50px !important;
  box-shadow: none !important;
  border: none !important;
}

.whyapply .rec-carousel .rec-arrow.rec-arrow-right {
  right: 0 !important;
  left: inherit !important;
  padding-bottom: 0px;
  padding-left: 0px;
}

.whyapply .rec-carousel .rec-arrow.rec-arrow-left {
  padding-bottom: 0px;
  padding-right: 0px;
  transform: rotate(180deg);
}

.whyapply .rec-carousel-item .rec-item-wrapper .slidebox img {
  position: relative;
  z-index: 3;
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-bottom: 30px;
}

.blog-content>p>a {
  color: #d25029;
}

.blog-content>a {
  color: #d25029;
}

.whyapply .rec-carousel-item .rec-item-wrapper .slidebox {
  position: relative;
  overflow: hidden;
}

.whyapply .rec-carousel-item .rec-item-wrapper .slidebox h4 {
  position: relative;
  z-index: 3;
  font-size: 18.718px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 20px;
}

.whyapply .rec-carousel-item .rec-item-wrapper .slidebox p {
  position: relative;
  z-index: 3;
  font-family: Graphik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: 0.187px;
}

.rightbar-nav .language-moblie {
  margin: 0;
}

.language-moblie {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  gap: 10px;
}

.whyapply .rec-pagination {
  display: none !important;
}

.whyapply .rec-carousel-item .rec {
  height: 100%;
}

.whyapply .rec-carousel-item .rec-item-wrapper .slidebox {
  position: relative;
  height: 100%;
}

.whyapply .rec-carousel-item .rec-item-wrapper .slidebox::after {
  content: "";
  width: 400px;
  height: 400px;
  transform: rotate(108.076deg);
  border-radius: 400px;
  border: 1.062px solid #333;
  background: linear-gradient(180deg, #2f2f31 0%, #1d1e20 100%);
  box-shadow: 0px 14.868px 42.479px 0px rgba(0, 0, 0, 0.35);
  top: -50%;
  right: -50%;
  position: absolute;
  z-index: 1;
}

.whyapply .rec-carousel-item .rec-item-wrapper .slidebox::before {
  content: "";
  width: 400px;
  height: 400px;
  transform: rotate(108.076deg);
  border-radius: 400px;
  border: 1.062px solid #333;
  background: linear-gradient(180deg, #2f2f31 0%, #1d1e20 100%);
  box-shadow: 0px 14.868px 42.479px 0px rgba(0, 0, 0, 0.35);
  bottom: -50%;
  left: -50%;
  position: absolute;
  z-index: 1;
}

.whyapply .btngroup {
  margin-top: 80px;
}

.whyapply .btngroup {
  margin-top: 80px;
}

td>button {
  background: #ee7a3c;
  cursor: pointer;
  padding: 6px 9px;
  font-size: 12px;
  font-weight: 500;
  outline: none;
  border-radius: 10px;
  font-family: "Termina Test", sans-serif;
  border: none;
  color: white;
}

.KeyNumber {
  padding: 70px 0;
  border-radius: 22.694px;
  background: linear-gradient(180deg, #181717 0%, #1d1e20 100%);
  box-shadow: 0 12px 62px 30px #0000001f;
}

.linebarbox {
  text-align: center;
}

.linebarbox h3 {
  position: relative;
  z-index: 2;
}

.countNumber h5 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 0px;
  margin-top: 30px;
  text-transform: capitalize;
}

.pie-parent {
  position: relative !important;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LookingFor {
  padding: 70px 0;
}

.LookingFortabs-block {
  border: none;
  margin-bottom: 50px;
  column-gap: 20px;
}

.lookingservice .carside {
  overflow: hidden;
}

.lookingservice .carside img {
  transition: 0.4s ease;
  transform: translate(0%, 0px);
}

.lookingservice:hover .carside img {
  transform: translate(8%, 0px);
}

.LookingFortabs-block li .nav-link {
  padding: 20px 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 100px;
  border: 1.171px solid #333;
  background: linear-gradient(180deg, #2f2f31 0%, #1d1e20 100%);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: #ffffff;
  min-width: 280px;
  transition: 0.4s ease;
  font-family: "Termina Test", sans-serif;
}

.LookingFortabs-block li .nav-link.active,
.LookingFortabs-block li .nav-link:hover {
  outline: none;
  background: linear-gradient(180deg, #ff6b00 0%, #ac3700 100%);
  border-color: #ff6b00;
  color: #ffffff;
}

.LookingForTabs .LookingFortabs-block {
  margin-bottom: 70px;
}

.primary-title.title-leftside {
  text-align: left;
}

.primary-title.title-leftside .bar-line {
  margin: 0 auto 0 0;
}

.gridbar {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 30px;
}

.lookingservice {
  border-radius: 22.715px;
  border: 1.136px solid #333;
  background: linear-gradient(270deg, #2f2f31 0%, #1d1e20 100%);
  box-shadow: 0px 3.881px 3.881px 0px rgba(0, 0, 0, 0.25);
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.lookingservice .carside {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 22.715px 0px 0px 22.715px;
  border: 1.136px solid #333;
  background: linear-gradient(270deg, #1d1e20 0%, #232426 25%, #2f2f31 100%);
  box-shadow: 3.881px 3.881px 9.701px 1.94px rgba(0, 0, 0, 0.25);
  padding: 10px !important;
}

.lookingservice .service-content {
  padding: 20px;
  position: relative;
}

.lookingservice .service-content h4 {
  margin: 0;
  background: linear-gradient(90deg, #848484 0.16%, #dedede 85.55%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  padding: 18px 0;
  position: relative;
}

.lookingservice .service-content h4::after,
.lookingservice .service-content h4::before {
  content: "";
  stroke-width: 0.97px;
  background: #fff;
  opacity: 0.75;
  width: 97.015px;
  height: 2px;
  position: absolute;
  transition: 0.4s ease;
}

.LookingFor .btngroup {
  justify-content: flex-start;
  margin-bottom: 100px;
}

.lookingservice .service-content:hover h4::before {
  right: 90px;
}

.lookingservice .service-content h4::before {
  top: 0;
  right: 0;
}

.lookingservice .service-content h4::after {
  bottom: 0;
  left: 0;
}

.lookingservice .service-content:hover h4::after {
  left: 80px;
}

.LookingFor .btngroup {
  justify-content: flex-start;
  margin-bottom: 100px;
}

.CareerOpportunitydivider {
  padding: 70px 0;
  background: linear-gradient(180deg, #ff6b00 0%, #ac3700 156.15%);
  box-shadow: 0px 4px 31px 0px rgba(0, 0, 0, 0.15);
}

.CareerOpportunitydivider .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CareerOpportunitydivider .container h4 {
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin: 0;
}

.let-talk-block {
  padding: 70px 0;
}

.let-talk-block .letstalk-inner {
  padding: 110px 80px 60px 80px !important;
  border-radius: 14.039px;
  background: #2e2e30;
}

.let-talk-block .letstalk-inner h6 {
  background: linear-gradient(180deg, #ff6b00 0%, #ac3700 156.15%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.let-talk-block .letstalk-inner h3 {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0.2px;
  margin-bottom: 40px;
}

.let-talk-block .letstalk-inner::after {
  content: "";
  border-radius: 500px;
  border: 1.135px solid #333;
  background: linear-gradient(180deg, #2f2f31 0%, #1d1e20 100%);
  box-shadow: 0px 15.886px 45.388px 0px rgba(0, 0, 0, 0.35);
  width: 500px;
  height: 500px;
  position: absolute;
  bottom: -310px;
  left: 50%;
  z-index: 1;
}

.let-talk-block .letstalk-inner::before {
  content: "";
  border-radius: 500px;
  border: 1.135px solid #333;
  background: linear-gradient(180deg, #2f2f31 0%, #1d1e20 100%);
  box-shadow: 0px 15.886px 45.388px 0px rgba(0, 0, 0, 0.35);
  width: 500px;
  height: 500px;
  position: absolute;
  top: -190px;
  left: -140px;
  z-index: 1;
}

.let-talk-block .letstalk-inner {
  position: relative;
  overflow: hidden;
}

.let-talk-block .letstalk-inner h6,
.let-talk-block .letstalk-inner h3,
.let-talk-block .letstalk-inner .btn-radius {
  position: relative;
  z-index: 4;
}

.btngrop-leftalign {
  justify-content: flex-start;
}

.btngrop-leftalign .btn {
  padding: 9px 30px;
  font-size: 14px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.users-table p {
  font-weight: 300;
}

.cookie-consent-bar {
  width: 350px;
  position: fixed;
  bottom: 0;
  right: 0;
  background: #212121;
  padding: 24px;
  border-radius: 10px 0 0 0;
  z-index: 999;
}

.cookie-consent-bar h6 {
  font-family: Graphik, sans-serif !important;
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 5px 0;
  font-weight: 600;
}

.cookie-consent-bar .btninlinebar {
  display: grid;
  gap: 10px;
  grid-template-columns: 49% 49%;
}

.cookie-consent-bar .btn {
  padding: 10px 6px;
  font-size: 12px;
  width: auto !important;
  line-height: 14px;
}

.cookie-consent-bar .btngroupbar {
  display: grid;
  gap: 10px;
}

.cookie-consent-bar p {
  font-family: "Termina Test", sans-serif;
  line-height: 16px;
  font-size: 12px;
  margin-bottom: 20px;
}

.footerbar {
  padding: 70px 0 0 0;
  background-image: url("../../public/images/footbg.webp");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.footerbar::before {
  content: "";
  background: linear-gradient(180deg,
      rgba(24, 23, 23, 0) 8.15%,
      #181717 93.46%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.footerbar .container {
  position: relative;
  z-index: 23;
}

.footerbar .foot-logobar {
  margin-bottom: 40px;
}

.footerbar .footerleft h6 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.3px;
  margin-bottom: 30px;
}

.footernavbar {
  display: flex;
  justify-content: flex-start;
  column-gap: 96px;
  max-width: 60%;
}

.footernavbar li {
  flex: 0 0 20%;
  max-width: 20%;
  margin-bottom: 20px;
}

.footernavbar li a {
  font-size: 15px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.205px;
  font-weight: 500;
}

.Copyrightbar {
  padding: 70px 0 40px 0;
}

.copyryt-left {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
}

.copyryt-left li a {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.205px;
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 40px;
}

.social-icon li a {
  font-size: 24px;
  cursor: pointer;
  transition: 0.4s ease;
}

.step-form-container .block-bar form {
  margin-top: 70px;
}

.step-form-container .block-bar .btnform {
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
}

.step-form-container.step-8 .formrow .btnbar {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.social-icon li a:hover {
  color: #fe5028;
}

.footernavbar li a:hover,
.footernavbar li a.active,
.copyryt-left li a:hover {
  color: #fe5028;
}

.step-form-container .block-bar {
  padding: 60px 40px;
  box-shadow: 0px 15.886px 45.388px 0px rgba(0, 0, 0, 0.35);
  background-image: url("../../public/images/pattern.webp");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.bar-line {
  margin-bottom: 30px;
}

.step-form-container .block-bar::before {
  padding: 60px 40px;
  border-radius: 22.694px;
  border: 1.135px solid #333;
  background: linear-gradient(180deg, #2f2f31 0%, #1d1e20 100%);
}

.step-form-container .block-bar .formsteps {
  width: 100%;
}

.quick-block {
  padding: 70px 0 50px 0;
}

.step-form-container .block-bar .line {
  border-radius: 72.707px;
  background: #494949;
  width: 100%;
  height: 5px;
  position: relative;
}

.formrow .forminput .css-13cymwt-control {
  padding: 0 0 0px 0;
  border: none !important;
  outline: none !important;
  background: transparent !important;
}

.formrow .forminput .css-13cymwt-control {
  position: relative;
}

.formrow .forminput .css-13cymwt-control .css-1xc3v61-indicatorContainer {
  position: absolute;
  right: 0;
  padding: 10px;
}

.formrow .forminput .css-13cymwt-control span.css-1u9des2-indicatorSeparator {
  display: none;
}

.css-1nmdiq5-menu {
  background-color: #252628 !important;
}

.formrow .forminput .css-13cymwt-control .css-1fdsijx-ValueContainer {
  border-radius: 5px;
  border: 2px solid #191a1d;
  background: #252628 !important;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.15) inset !important;
  padding: 18px 28px;

  font-family: Graphik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #ffffff;
  outline: none !important;
}

.formrow .forminput .css-13cymwt-control .css-1fdsijx-ValueContainer .css-1jqq78o-placeholder {
  color: rgb(255 255 255 / 80%);
}

.css-1dimb5e-singleValue {
  color: #ffffff;
}

.formrow .dflex-inputbar {
  margin-bottom: 20px;
}

.btnbar.twobtncenter {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.job-apply-form .step-1 .formsteps,
.job-apply-form .step-11 .formsteps,
.job-apply-form .step-12 .formsteps {
  max-width: 767px;
  margin: 0 auto;
}

.step-form-container .block-bar form select.form-control option,
.step-form-container .block-bar .form select.form-control option {
  font-weight: 500;
}

.step-form-container .block-bar form .form-control,
.step-form-container .block-bar .form .form-control {
  border-radius: 5px;
  border: 2px solid #191a1d;
  background-color: #252628 !important;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.15) inset !important;
  padding: 18px 28px !important;

  font-family: Graphik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #ffffff;
  outline: none !important;
}

.dropdownbar .form-control {
  appearance: none;

  background-size: 24px;
  background-repeat: no-repeat;
  background-position: 94% 15px;
  outline: none;
}

.reumeupload-form.formtitle {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  font-family: Graphik, sans-serif;
  margin: 0 0 10px 0 !important;
}

.let-talk-block .letstalk-inner .btnsubmit {
  text-align: center;
  position: relative;
  margin-top: 30px;
}

.uploadingarea {
  position: relative;
  z-index: 10;
}

.uploadresumeblock .reumeupload-form .inputfile {
  margin: 0 0 0 0;
}

.uploadresumeblock .reumeupload-form .btnsubmit {
  flex: 0 0 100%;
  max-width: 100%;
}

.btnlogout {
  background: transparent;
  border: none;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-family: "Termina Test", sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.step-form-container .block-bar form .form-control::placeholder,
.step-form-container .block-bar form input::placeholder {
  color: #5f6265;
  text-transform: capitalize;
}

.modalcontact .modal-content {
  background: #252525;
}

.modalcontact .modal-content form {}

.modalcontact .modal-content form .forminput {}

.modalcontact .modal-content form .forminput .form-control {
  border-radius: 5px;
  border: 2px solid #191a1d;
  background-color: #252628 !important;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.15) inset !important;
  padding: 18px 28px !important;
  font-family: Graphik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #ffffff;
  outline: none !important;
}



.modalcontact .modal-content form .forminput .form-check {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.modalcontact .modal-content form .forminput .form-check label.form-check-label {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
  text-align: center;
}

.modalcontact .modal-content form .forminput .form-check .form-check-input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin: 0;
  flex: 0 0 20px;
}

.modalcontact .modal-content .modal-header {
  padding: 15px 30px;
  border: none;
}

.modalcontact .modal-content .modal-body {
  padding: 10px 30px 30px 30px;
}

.modalcontact .modal-content .modal-body .dflex-inputbar {
  padding: 0 10px;
}

.modalcontact .modal-content .modal-body .formrow {
  margin: 0 -10px;
}

.modalcontact .modal-content .modal-header button.btn-close {
  opacity: 1;
  filter: invert(1);
}

.formrow .form-100 select.form-control {
  background-position: 98.5% 15px !important;
  outline: none;
}

.step-form-container .block-bar form select.form-control {
  appearance: none;
  background-image: url("data:image.webp;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgEAYAAAAj6qa3AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGYktHRAAAAAAAAPlDu38AAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfnDAcJJTknlTdCAAABY0lEQVRo3u2WP0tCURjGn9eucINW7YqXWhqNBm0vxBMm9hGEvkANDdHY5NQQjUFDUwRtCZFaiOKiDamgUwQ1lNAXCPNtMTGkv3i6Gu9vvPdc3uf5nQPnAoIgCIIgCILwKcwqaacLBW6rA/9FqcTtyPVkxOt1PFcnB7NS/v1ymVnd+3fz+e9+7/rBrEu+NU0QjigRCoFoxzjMZp0S0Z3byQEAtB0MgnGOWdMcvAAeu3kpRqMA1vBUrQJ4oPlAAKB190oux7x0MnXm8+kvHp2xVj0eEN25C5lMNwfjGZuNBsiYMBbjce0BmNWpPV6pMCtl28zcVgv2Xr2uS8TXc5ePp9mydMp3RMTQFf8rEUNfXJeIkSs+KBEjX/y3IpwuTvpF9N3XnesTFpdqtZ6l758zb7QS4TC50nOP6WZTd07tfLjTDh917SegX8TbnyNdGVupVM+bYCsZi/2bHRcEQRAEQRCGnFft9I8e4VKaiAAAAABJRU5ErkJggg==");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: 97% 15px;
  outline: none;
  padding-right: 50px !important;
}

.step-form-container .block-bar .line::before {
  display: none;
}

.step-form-container .block-bar .line .lineinner {
  height: 5px;
  transition: 0.4s ease;
  transform-origin: left;
  border-radius: 100px;
  background: linear-gradient(180deg, #ff6b00 0%, #ac3700 156.15%);
}

.step-form-container .block-bar .btngroup {
  justify-content: flex-end !important;
}

.step-form-container.step-2 .block-bar .line::before {
  width: 25%;
}

.step-form-container.step-3 .block-bar .line::before {
  width: 37.5%;
}

.step-form-container.step-4 .block-bar .line::before {
  width: 50%;
}

.step-form-container.step-5 .block-bar .line::before {
  width: 62.5%;
}

.step-form-container.step-6 .block-bar .line::before {
  width: 75%;
}

.step-form-container.step-7 .block-bar .line::before {
  width: 87.5%;
}

.step-form-container.step-8 .block-bar .line::before {
  width: 100%;
}

.selectbox {
  display: block;
  overflow: hidden;
  position: relative;
  width: 212px;
  min-height: 235px;
  margin: 0 auto 20px auto;
}

.selectbox .primebox {
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 5px;
  border: 2px solid #191a1d;
  background: #252628;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.15) inset;

  margin-bottom: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 25px 30px;
  color: #858585;
  font-family: Graphik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.selectbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 20px;
  height: 20px;
  z-index: 1;
  left: 0;
}

.selectbox input:checked~.primebox {
  border: 2px solid #ff6b00;
  color: #ffffff;
}

.formsteps h4 {
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  margin-bottom: 20px;
  text-align: center;
}

.step-form-container .block-bar .formsteps {
  width: 100%;
}

.step-form-container .block-bar .btngroup {
  justify-content: flex-end !important;
}

.step-form-container .block-bar .formsteps .flexbar {
  display: grid;
  grid-template-columns: 48.5% 48.5%;
  column-gap: 20px;
}

.step-form-container.step-8 .formsteps {
  max-width: 760px;
  margin: 0 auto;
}

.step-form-container .block-bar .formsteps .flexbar label.selectbox .primebox {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.step-form-container .block-bar .formsteps .flexbar label.selectbox .primebox img {
  font-size: 70px;
  width: 70px;
  height: 70px;
  object-fit: contain;
  margin-bottom: 20px;
}

.step-form-container .block-bar .formsteps .flexbar label.selectbox .primebox h5 {
  margin: 0;
  color: #858585;
  text-align: center;
  text-shadow: 0px 0px 4.182px rgba(0, 0, 0, 0.8);
  font-family: Graphik, sans-serif;
  font-size: 22.302px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.089px;
}

.selectbox input:checked~.primebox h5 {
  color: #ffffff !important;
}

.step-form-container.step-1 .formsteps {
  max-width: 500px;
  margin: 0 auto;
}

.step-form-container.step-2 .formsteps,
.step-form-container.step-5 .formsteps,
.step-form-container.step-6 .formsteps,
.step-form-container.step-7 .formsteps {
  max-width: 760px;
  margin: 0 auto;
}

.step-form-container.step-3 .formsteps {
  margin: 0 auto;
  max-width: 70%;
}

.step-form-container.step-3 .formsteps .flexbar {
  grid-template-columns: 33.33% 33.33% 33.33%;
}

.step-form-container.step-4 .formsteps .flexbar {
  grid-template-columns: 25% 25% 25% 25% !important;
}

.step-form-container.step-5 .formsteps .flexbar {
  grid-template-columns: 25% 25% 25% 25% !important;
}

.step-form-container.step-4 .formsteps,
.step-form-container.step-5 .formsteps {
  max-width: 70%;
  margin: 0 auto;
}

.crisp-client .cc-tlyw .cc-kxkl .cc-nsge .cc-imbb {
  background: var(--text-gradient) !important;

  -webkit-animation: ripple 0s linear infinite;
  animation: ripple 0s linear infinite;
}

.crisp-client .cc-tlyw .cc-kxkl .cc-nsge .cc-imbb:before,
.crisp-client .cc-tlyw .cc-kxkl .cc-nsge .cc-imbb:after {
  content: "" !important;
  display: block !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  border: 1px solid #f16200 !important;
  border-radius: 50% !important;
}

.crisp-client .cc-tlyw .cc-kxkl .cc-nsge .cc-imbb:before {
  animation: ripple 2s linear infinite;
}

.crisp-client .cc-tlyw .cc-kxkl .cc-nsge .cc-imbb:after {
  animation: ripple 2s linear 1s infinite;
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
    opacity: 1;
  }

  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

.crisp-client .cc-tlyw .cc-kxkl .cc-nsge .cc-imbb:after {
  display: none;
}

.aboutcontent {
  padding: 140px 0 70px 0;
}

.content-p {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.8);
}

.aboutcontent .about-rytside .content-p {
  margin-bottom: 30px;
}

.aboutcontent .about-rytside .welder-img {
  margin-top: 80px;
}

.aboutcontent .about-rytside .welder-img img {
  width: 100%;
  height: 320px;
  object-fit: cover;
}

.Aboutbar .whyapply .btngroup {
  margin-top: 30px !important;
}

.benefitbar {
  padding: 50px 0;
}

.benefitbar {
  padding: 50px 0;
}

.listingbar {
  padding: 45px 60px;
  margin-bottom: 20px;
}

.listingbar .topbar-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 30px;
  margin-bottom: 32px;
}

.listingbar .topbar-title h6 {
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.listingbar p.content-p {
  margin-bottom: 20px;
}

.listing-ul li {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.8);
  padding-left: 20px;
  position: relative;
  margin-bottom: 10px;
}

.listing-ul li::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 5px;
  height: 5px;
  background: #ff6b00;
  border-radius: 50px;
  transform: translate(0px, -50%);
}

.ant-tabs-nav::before {
  display: none;
}

.ant-tabs .ant-tabs-ink-bar {
  background: #ec612f !important;
}

.ant-tabs .ant-tabs-tab-btn {
  font-size: 18px;
  font-family: "Termina Test", sans-serif;
}

.listingbar.bg-gray {
  border-radius: 14.039px;
  border-radius: 14.039px;
  background: #2e2e30;
}

.contact-banner {
  padding: 200px 0 60px 0;
  position: relative;
  background-image: url("../../public/images/contact-banner-bg.webp");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-banner .contact-head {
  margin-bottom: 70px;
}

.contact-banner .contact-head h6 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: Graphik, sans-serif;
  margin-bottom: 30px;
}

.contact-banner .contact-head h3 {
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
  margin-bottom: 50px;
}

.contact-banner .contact-head p {
  font-family: Graphik, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* 166.667% */
  letter-spacing: 0.2px;
}

.contact-banner-bottom ul.contactdetails {
  display: grid;
  grid-template-columns: 25% 25% 25%;
  column-gap: 40px;
}

.contact-banner-bottom ul.contactdetails li {
  border-right: 1px solid rgb(255 255 255 / 30%);
  max-width: 90%;
  width: 100%;
}

.contact-banner-bottom ul.contactdetails li:last-child {
  border-right: 0 !important;
}

.contact-banner-bottom ul.contactdetails li h4 {
  font-family: Graphik, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 14px;
  color: #ffffff;
}

.contact-banner-bottom ul.contactdetails li p {
  font-family: Graphik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.8);
}

.contact-banner::after {
  content: "";
  background: linear-gradient(180deg,
      rgba(24, 23, 23, 0) 8.15%,
      #181717 93.46%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.contact-banner .container {
  position: relative;
  z-index: 3;
}

.contactformbar {
  padding: 150px 0;
  position: relative;
}

.contactformbar h4 {
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
  margin-bottom: 50px;
}

.contactformbar form {
  position: relative;
}

.formrow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}

.render_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.render_row .dflex-inputbar {
  width: 100%;
}

.preline {
  white-space: pre-line;
}

.formrow .form-50 {
  flex: 0 0 50%;
  max-width: 50%;
}

.formrow .dflex-inputbar {
  padding: 0 20px;
}

.formrow .form-100 {
  flex: 0 0 100%;
  max-width: 100%;
}

.contactformbar form .formrow .forminput {
  margin-bottom: 40px;
}

.contactformbar form .formrow .dflex-inputbar .dflex-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contactformbar form .formrow .dflex-inputbar .dflex-bar .forminput {
  margin: 0;
}

.contactformbar form .formrow .dflex-inputbar .dflex-bar .forminput .form-check {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 16px;
}

.contactformbar form .formrow .dflex-inputbar .dflex-bar .forminput .form-check .form-check-input[type="checkbox"] {
  width: 25px;
  flex: 0 0 25px;
  height: 25px;
  margin: 0;
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 !important;
  float: none;
}

.contactformbar form .formrow .dflex-inputbar .dflex-bar .forminput .form-check label.form-check-label {
  color: #ffffff;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  line-height: 1;
}

.BlogBanner {
  padding: 200px 0 100px 0;
}

.BlogBanner .blog-head {
  text-align: center;
}

.BlogBanner .blog-head h2 {
  font-size: 45px;
  font-style: normal;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: 0.2px;
  margin-bottom: 40px;
}

.BlogBanner .blog-head p {
  font-family: Graphik, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.2px;
}

.bloglisting {
  grid-column-gap: 50px;
  border-bottom: 1px solid #ffffff4d;
  column-gap: 40px;
  display: grid;
  margin-bottom: 48px;
  padding-bottom: 48px;
  grid-template-columns: 60% 40%;
}

.allblogs {
  padding: 70px 0 70px 0;
}

.custom-top {
  position: relative;
  top: -15px;
}

.bloglisting .blog-title .title-tophead {
  display: flex;
  align-items: center;
  column-gap: 20px;
  justify-content: flex-start;
  margin-bottom: 25px;
}

.allblogs .bloglisting:last-child {
  border: none;
}

.bloglisting .blog-title h5 {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 37px;
}

.bloglisting .blog-title h6 {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  font-family: Graphik, sans-serif;
  color: rgb(255 255 255 / 50%);
  margin: 0;
}

.bloglisting .blog-content-detail {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex: 0 0 40%;
  height: 400px;
}

.bloglisting .blog-content-detail p {
  color: #fff;
  font-family: Graphik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: rgb(255 255 255 / 80%);
  flex: 0 0 70%;
}

.doc-btn {
  width: 509px;
  min-height: 48px;
  font-family: "Termina Test", sans-serif;
  background: #d25029;
  color: white;
  font-size: 14px;
}

.bloglisting .blog-content-detail a svg {
  font-size: 50px;
  color: rgba(255, 255, 255, 0.3);
  transition: 0.4s ease;
}

.bloglisting .blog-content-detail img {
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.bloglisting .blog-content-detail a:hover svg {
  color: #da2e13;
  transform: rotate(-45deg);
}

.articleblog-block {
  padding: 0 0 70px 0;
}

.articleblog-block h4 {
  font-family: Graphik, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  margin-bottom: 18px;
}

.Articlebloglisting {
  border-radius: 5px;
  border: 2px solid rgba(21, 6, 45, 0.73);
  background: linear-gradient(180deg,
      rgba(196, 196, 196, 0.02) 0%,
      rgba(255, 255, 255, 0.04) 100%);
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  padding: 27px;
  grid-template-columns: 39% 59%;
}

.bloglisting .blog-title h6 {
  column-gap: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bloglisting .blog-title p {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.7);
}

.bloglisting .blog-title .blogbtn {
  margin-top: 30px;
}

.bloglisting .blog-title {
  flex: 0 0 60%;
}

.allblogs .blogtab .nav-link.disabled {
  padding: 0;
  border: none;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 37px;
  text-align: left;
  color: #ffffff;
}

.allblogs .blogtab .nav-link {
  padding: 12px 40px;
  background: transparent !important;
  border: none !important;
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: max-content;
}

.allblogs .blogtab .nav-link.active {
  border-radius: 50px !important;
  border: 1.135px solid #333 !important;
  background: linear-gradient(180deg, #2f2f31 0%, #1d1e20 100%) !important;
}

/* * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
} */
.jobdetail-accordion .accordion .accordion-item .accordion-button[aria-expanded="true"] {
  border-radius: 22.694px 22.694px 0 0;
  border-color: #ff6b00;
}

.jobdetail-accordion .accordion .accordion-item .accordion-collapse .accordion-body {
  border-radius: 0 0 22.694px 22.694px;
  border: 1px solid transparent;
  border-top: 0;
}

.jobdetail-accordion .accordion .accordion-item .accordion-collapse.collapse.show .accordion-body {
  border-color: #ff6b00;
}

.step-form-container.step-9 .formsteps,
.step-form-container.step-10 .formsteps {
  max-width: 60%;
  margin: 0 auto;
}

.step-form-container .block-bar .formsteps {
  width: 100%;
}

.step-form-container .block-bar .btngroup {
  justify-content: flex-end !important;
}

.step-form-container .block-bar .formsteps .flexbar {
  display: grid;
  grid-template-columns: 48.5% 48.5%;
  column-gap: 20px;
}

label.selectbox.h-auto {
  min-height: auto;
}

.step-form-container .block-bar .formsteps .flexbar label.selectbox .primebox {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.step-form-container .block-bar .formsteps .flexbar label.selectbox .primebox img {
  font-size: 70px;
  width: 70px;
  height: 70px;
  object-fit: contain;
  margin-bottom: 20px;
}

.step-form-container .block-bar .formsteps .flexbar label.selectbox .primebox h5 {
  margin: 0;
  color: #858585;
  text-align: center;
  text-shadow: 0px 0px 4.182px rgba(0, 0, 0, 0.8);
  font-family: Graphik, sans-serif;
  font-size: 22.302px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.089px;
}

.error {
  font-size: 14px;
  font-weight: 500;
  color: red;
  text-transform: capitalize;
}

.selectbox input:checked~.primebox h5 {
  color: #ffffff !important;
}

.step-form-container.step-3 .formsteps .flexbar {
  grid-template-columns: 25% 25% 25% 25%;
}

.aboutbanner {
  background-image: url("../../public/images/aboutbannerbg.webp");
  padding: 210px 0 100px 0;
  position: relative;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.listingbar .topbar-title img {
  width: 50px;
}

.uploadresumeblock .reumeupload-form {
  position: relative;
  z-index: 3;
  max-width: 900px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 20px;
}

.uploadresumeblock .reumeupload-form .multiselect-bar .searchWrapper {
  padding: 0;
  border: none;
  min-height: auto;
  display: flex;
  flex-direction: column-reverse;
  gap: 1px;
}

.uploadresumeblock .reumeupload-form .multiselect-bar .searchWrapper input {
  margin: 0px 0 10px 0;
  border: 1px solid var(--white) !important;
  padding: 12px 40px;
  border-radius: 50px;
  width: 100%;
  font-weight: 500;
}

.uploadresumeblock .reumeupload-form .inputfile {
  margin: 0px 0;
}

.uploadresumeblock .reumeupload-form .inputfile input.form-control {
  border: 1px solid #ffff !important;
  padding: 15px 30px;
  border-radius: 50px;
}

.uploadresumeblock .reumeupload-form .multiselect-bar .optionListContainer {
  background: #181818 !important;
  z-index: 99 !important;
}

.multiselect-bar .select__indicators {
  display: none;
}

.multiselect-bar .select__value-container .select__multi-value {
  border-radius: 20px;
  color: #ffffff !important;
  background: linear-gradient(180deg, #fa6800 0%, #cc4b00 100%);
  cursor: pointer;
}

.select__menu {
  background: transparent !important;
}

.select__menu .select__option {
  background: #2d2d2f;
}

.select__menu .select__option:hover {
  background: #e65d2e;
}

.multiselect-bar .select__value-container .select__multi-value .select__multi-value__label {
  color: #ffffff;
  font-family: "Termina Test", sans-serif;
}

.multiselect-bar .select__value-container {
  background: transparent;
  border: none !important;
  outline: none !important;
  padding: 0 !important;
  color: #ffffff !important;
}

.multiselect-bar .select__control {
  margin: 0 !important;
  border: 1px solid #ffff !important;
  padding: 12px 20px;
  border-radius: 50px;
  background: transparent !important;
}



.multiselect-bar .select__value-container .css-1jqq78o-placeholder {
  color: #ffffff;
  font-family: "Termina Test", sans-serif;
  font-size: 14px;
}

.uploadresumeblock .reumeupload-form .multiselect-bar .optionListContainer .highlightOption,
.uploadresumeblock .reumeupload-form .multiselect-bar .optionListContainer .highlightOption:hover {
  background: #ba4603 !important;
}

.uploadresumeblock .reumeupload-form .multiselect-bar .optionListContainer li {
  font-size: 16px;
  font-family: "Termina Test", sans-serif;
  font-weight: 400;
  background: transparent !important;
}

.uploadresumeblock .reumeupload-form .multiselect-bar .searchWrapper .chip {
  background: #f06100 !important;
  font-size: 12px;
  font-family: "Termina Test", sans-serif;
}

.uploadresumeblock .reumeupload-form .inputfile p {
  font-family: "Termina Test", sans-serif;
  font-size: 10px;
  margin: 0px 0 0 0;
}

.uploadresumeblock h3 {
  text-align: center;
}

.uploadresumeblock .reumeupload-form .btnsubmit {
  text-align: center;
}

.aboutbanner h2 {
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
  margin-bottom: 30px;
}

.aboutbanner .bar-line {
  margin-bottom: 30px;
}

.aboutbanner p {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* 166.667% */
  letter-spacing: 0.2px;
  max-width: 652px;
  margin: 0 auto;
}

.aboutbanner::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(24 23 23 / 50%);
}

.aboutbanner .container {
  position: relative;
  z-index: 3;
}

.aboutcontent {
  padding: 70px 0 70px 0;
}

.content-p {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  /* 22.5px */
  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.8);
}

.aboutcontent .about-rytside .content-p {
  margin-bottom: 30px;
}

.aboutcontent .about-rytside .welder-img {
  margin-top: 30px;
}

.aboutcontent .about-rytside .welder-img img {
  width: 100%;
  height: 320px;
  object-fit: cover;
}

.aboutleft-img img {
  width: 100%;
  object-fit: cover;
}

.Aboutbar .whyapply {
  padding: 0px 0 0px 0;
}

.Aboutbar .whyapply .btngroup {
  margin-top: 30px !important;
}

.benefitbar {
  padding: 70px 0;
}

.about-listing {
  padding: 70px 0 0;
}

.listingbar {
  padding: 45px 60px;
  margin-bottom: 20px;
}

.listingbar .topbar-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 30px;
  margin-bottom: 32px;
}

.listingbar .topbar-title h6 {
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.listingbar p.content-p {
  margin-bottom: 20px;
}

.listing-ul li {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.8);
  padding-left: 20px;
  position: relative;
  margin-bottom: 10px;
}

.listing-ul li::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 5px;
  height: 5px;
  background: #ff6b00;
  border-radius: 50px;
  transform: translate(0px, -50%);
}

.listingbar.bg-gray {
  border-radius: 14.039px;
  border-radius: 14.039px;
  background: #2e2e30;
}

.contactus {
  padding: 70px 0 70px 0;
  position: relative;
  background-image: url("../../public/images/cn-bg.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contactus .title-contact h3 {
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  margin-bottom: 50px;
}

.BlogDetailBanner .Categorybar {
  margin-bottom: 25px;
  text-align: center;
}

.BlogDetailBanner .Categorybar h5 {
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  padding: 17px 34px;
  border-radius: 50px;
  border: 1.135px solid #333;
  background: linear-gradient(180deg, #2f2f31 0%, #1d1e20 100%);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 15.4px;
  font-family: Graphik, sans-serif;
}

.BlogDetailBanner .blogdate {
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  color: rgba(255, 255, 255, 0.7);
  font-family: Graphik, sans-serif;
}

.blog-content h2 {
  font-size: 28px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}

.blogdetail-content .featured-img img {
  width: 100%;
  height: 440px;
  object-fit: contain;
}

.blogdetail-content .featured-img {
  max-width: 1078px;
  width: 100%;
  margin: 0 auto 70px !important;
}

.blogdetail-wrapper {
  max-width: 860px;
  margin: 0 auto;
}

.blogdetail-wrapper p,
.blogdetail-wrapper ul li {
  color: rgba(255, 255, 255, 0.8);
  font-family: Graphik, sans-serif;
  font-size: 14.444px;
  font-style: normal;
  font-weight: 300;
  line-height: 23.472px;
  margin-bottom: 20px;
}

.spacer-50 {
  padding: 25px;
}

.blogdetail-wrapper .featured-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blogdetail-wrapper .featured-img {
  height: 360px;
  margin: 60px 0 60px 0;
}

.blogdetail-wrapper h3 {
  font-size: 27.986px;
  font-style: normal;
  font-weight: 500;
  line-height: 33.403px;
  margin-bottom: 30px;
}

.blogdetail-wrapper ul li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
}

.blogdetail-wrapper ul li::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 5px;
  height: 5px;
  background: #ff6b00;
  border-radius: 50px;
  transform: translate(0px, -50%);
}

.introduction {
  font-weight: 300;
}

.articles-block {
  padding: 50px 0 100px 0;
  position: relative;
}

.articles-block .container {
  padding-top: 100px;
  border-top: 2px solid rgb(255 255 255 / 40%);
}

.articles-block h3 {
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 52.361px;
  margin-bottom: 50px;
  text-align: center;
}

.Otherarticle {
  border-radius: 20px;
  border: 1.135px solid #333;
  background: linear-gradient(180deg, #2f2f31 0%, #1d1e20 100%);
  box-shadow: 0px 15.886px 45.388px 0px rgba(0, 0, 0, 0.35);
  padding: 20px 20px 30px 20px;
  height: 100%;
}

.Otherarticle .article-img {
  height: 200px;
  border-radius: 20px;
  margin-bottom: 20px;
}

.Otherarticle .article-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.Otherarticle .articles-detail {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 16px;
  margin-bottom: 24px;
}

.Otherarticle .articles-detail .btn {
  padding: 10px 20px;
  font-size: 10.1px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.Otherarticle .articles-detail h5.article-date {
  margin: 0;
  font-family: Graphik, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.408px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
  color: rgba(255, 255, 255, 0.7);
}

.Otherarticle .article-title {
  position: relative;
  font-size: 20px;
  line-height: 31.6px;
  font-weight: 500;
  color: #fff;
  text-align: left;
  display: inline-block;
  margin: 0;
}

.filterbar {
  padding: 0 0 35px 0;
}

.filterbar .filterrow {
  display: grid;
  grid-template-columns: auto auto auto auto;
  column-gap: 28px;
  align-items: center;
}

.searchbar {
  position: relative;
}

.searchbar .dflex {
  position: relative;
}

.searchbar .dflex input.inputsearch {
  padding: 16px 27px 16px 60px;
  border-radius: 50px;
  border: 1px solid #ffffff !important;
  background: transparent;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #ffffff;
  outline: none;
  width: 100%;
}

.searchbar .dflex .inputicon {
  position: absolute;
  top: 50%;
  z-index: 2;
  left: 27px;
  transform: translate(0px, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchbar .dflex input.inputsearch::placeholder {
  color: #ffffff;
}

.searchbar .dflex .inputicon svg {
  font-size: 25px;
  line-height: 1;
}

.filter-dropdown {
  padding: 16px 27px 16px 27px;
  border-radius: 50px;
  border: 2px solid #555 !important;
  background: transparent;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #ffffff;
  outline: none;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../../public/images/down-arrow.webp");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: 94% 15px;
  outline: none;
}

.filterbar .filterrow .btnconfirm {
  text-align: right;
}

.filterbar .filterrow .btnconfirm .btn {
  padding: 16px 48px;
}

.filter-dropdown option {
  background: #181717;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.filter-dropdown option {
  background: #181717;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.job-apply-form {
  padding-bottom: 60px;
}

.joblistingbar {
  padding: 50px 60px;
  border-radius: 22.69px;
  background: linear-gradient(180deg, #2f2f31, #1d1e20);
  box-shadow: 0px 15.885713577270508px 45.39px rgba(0, 0, 0, 0.35);
  border: 1.1px solid #333;
  margin-bottom: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
}

.joblistingbar .jobtitle {
  font-size: 23px;
  line-height: 35px;
  font-weight: 500;
  margin: 0;
}

.joblistingbar ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 22px;
}

.joblistingbar ul li.btn-gps {
  margin-left: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  font-size: 16px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
}

.joblistingbar ul li.btn-gps svg {
  font-size: 24px;
}

.joblistingbar ul .btnpitch {
  border-radius: 65.79px;
  background-color: #363636;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  font-weight: 500;
}

.joblistingbar .btnjob {
  text-align: right;
}

.alljobs-listing {
  padding: 35px 0;
}

.alljobs-listing h4 {
  margin-bottom: 32px;
  font-size: 24px;
  line-height: 37px;
  font-weight: 400;
  text-align: left;
}

.alljobs-listing h4 span {
  background: var(--Gradient-ok,
      linear-gradient(180deg, #ff6b00 0%, #ac3700 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.applyjob-conditions {
  padding: 50px 0 100px 0;
}

.applyjob-conditions h4 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #ffffff;
  margin-bottom: 30px;
}

.applyjob-conditions p {
  color: #fff;
  font-family: Graphik, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  margin-bottom: 12px;
}

.spacer-40 {
  padding: 20px 0;
}

.applyjob-conditions h6 {
  color: #fff;
  font-family: Graphik, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 5px;
}

.spacer-70 {
  padding: 35px 0;
}

.step-form-container.step-7 .formsteps form .forminput {
  margin-bottom: 20px;
}

.step-form-container.step-7 .formsteps form .forminput .form-control {
  color: #fff;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
  font-family: Graphik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 112.5% */
  padding: 25px 30px;
  border-radius: 5px;
  border: 2px solid #191a1d !important;
  background-color: #252628 !important;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.15) inset !important;
}

.step-form-container.step-7 .formsteps form .forminput .form-control:focus {
  border: 2px solid #ff6b00 !important;
}

.step-form-container.step-7 .formsteps form .btnbar {
  margin-top: 10px;
}

.step-form-container.step-7 .formsteps form .btnbar .btnorange {
  padding: 16px 60px;
}

.step-form-container.step-7 .formsteps form .forminput .form-select {
  appearance: none;
  background-image: url("../../public/images/down-arrow.webp");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center right;
  outline: none;
}

.fade {
  padding: 0 !important;
}

.darkmode-modal .modal-content {
  background: var(--gray-gradient);
  border: none;
}

.modal-backdrop {
  opacity: 0.6 !important;
}

.darkmode-modal .modal-content .modal-header .modal-title {
  color: #ffffff;
}

.darkmode-modal .modal-content .modal-header .btn-close {
  filter: invert(1);
  opacity: 1;
}

.darkmode-modal .modal-content .modal-body .userrole-detail-modal p {
  font-family: Graphik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  margin-bottom: 20px;
}

.darkmode-modal .modal-content .modal-body .userrole-detail-modal .btngroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.darkmode-modal .modal-content .modal-body .userrole-detail-modal .btngroup .btn {
  padding: 10px 10px;
}

.modal-dialog {
  max-width: 600px;
}

.auth-form {
  padding: 100px 0;
}

.auth-form form label.form-label {
  font-size: 16px;
  font-weight: 500;
}

.auth-form form .form-control {
  border-radius: 5px;
  border: 2px solid #191a1d;
  background: #252628 !important;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.15) inset !important;
  padding: 18px 28px;

  font-family: Graphik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #ffffff;
  outline: none !important;
}

.auth-form form .form-text {
  font-weight: 500;
}

.auth-form form .form-check {
  min-height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 15px;
  padding: 0;
  margin: 20px 0;
}

.auth-form form .form-check .form-check-input[type="checkbox"] {
  margin: 0;
  float: none;
  width: 25px;
  height: 25px;
  flex: 0 0 25px;
}

.auth-form form .form-check label.form-check-label {
  font-weight: 500;
}

.auth-form form {
  max-width: 600px;
  margin: 0 auto;
  background: var(--lightgray-gradient);
  padding: 40px;
  border-radius: 10px;
}

/* admin  */

.main-page {
  padding: 20px 0;
}

h1.heading {
  margin-bottom: 35px;
  font-size: 29px;
}

.top-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.sidebarprofile-sec img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 50%;
}

.input-date-bar input::-webkit-clear-button {
  display: none;
}

.page-name {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.page-name h4 {
  margin-bottom: 0;
}

.back-btn {
  background: white;
  border: none;
}

.import-export {
  display: flex;
  align-items: center;
  column-gap: 22px;
  width: fit-content;
}

.remove-inputbar .dflex-inputbar.form-100 {
  position: relative;
}

.remove-inputbar .dflex-inputbar.form-100 .removebtn {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translate(0px, -50%);
  z-index: 2;
  cursor: pointer;
}

.remove-inputbar .dflex-inputbar.form-100 .removebtn span:hover {
  color: #e25a2c;
}

.import-export button {
  color: #ee7a61;
  display: flex;
  border: none;
  background: white;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  column-gap: 8px;
}

.formrow .dflex-inputbar .btnlignself {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 10px;
}

.formrow .dflex-inputbar .btnlignself .btn {
  padding: 10px 15px;
  font-size: 12px;
  line-height: 14px;
}

.formrow .dflex-inputbar .btnlignself label.form-label {
  margin: 0;
}

.top-cards {
  gap: 20px;
  padding: 20px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.top-cards .info-card {
  height: 100%;
}

.card-details {
  display: flex;
  /* flex-direction: row; */
  /* gap: 14px; */
  justify-content: space-between;
  align-items: flex-start;
}

.card-content {
  width: 80% !important;
}

.card-details .crypto {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.crypto h4 {
  margin-bottom: 0;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #404040;
  white-space: nowrap;
}

.heading-text {
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #9f9f9f;
}

.card-details .percentage {
  padding: 2px 6px;
  border-radius: 100px;
  background: #e7f7ed;
  color: #287648;
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.card-details a {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #ee7a61;
}

.balance {
  margin-top: 8px;
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0px;
  color: #404040;
}

.chart-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}

.period-box button,
.period-box .select {
  background: white;
  padding: 11px 14px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  color: #404040;
  transition: 0.4s ease-in-out;
  flex: 1 1 48%;
}

.period-box select {
  border: none;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  color: #404040;
  transition: 0.4s ease-in-out;
}

.chart {
  padding: 16px;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  margin: 20px 0;
}

.period-box {
  flex: 0 0 45%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
}

.period-box button {
  display: flex;
  align-items: center;
  column-gap: 14px;
  padding: 13px 14px !important;
}

.period-box button:hover,
.period-box .select:hover {
  background: #d7d7d7;
}

.period-box .select:hover select {
  background: #d7d7d7;
}

/* -------------------------users-------------------- */

.serachbar-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0;
  column-gap: 16px;
}

.searchbar {
  flex: 1 1 80%;
}

.filter {
  flex: 1 1 20%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 7px 10px;
  border-radius: 8px;
  border: 1px solid #d7d7d7;
  color: #9f9f9f;
  column-gap: 8px;
}

.filter select {
  width: 100%;
  border: none;
  color: #9f9f9f;
}

.searchbar .input-group-text {
  background: transparent;
  font-size: 20px;
  color: #9f9f9f;
  border: 1px solid #dfdfdf;
  border-right: 0;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.searchbar .form-control {
  height: 46px;
  color: #9f9f9f !important;
  border-left: 0;
}

.user-details {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.user-name {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
  color: #404040;
}

.user-mail {
  color: #9f9f9f;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
}

table thead {
  border-bottom: 10px solid transparent;
}

button.ant-pagination-item-link {
  background: #ffffff !important;
  color: #000000 !important;
}

table tr th {
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #9f9f9f;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  text-align: left;
  padding: 8px 12px !important;
}

table tr td:nth-child(2) {
  text-align: left;
}

tbody tr {
  background: #f9f9f9;
  border-bottom: 10px solid white;
  vertical-align: middle;
  height: 57px;
}

tbody tr td:first-child {
  border-radius: 8px 0 0 8px;
}

tbody tr td:last-child {
  border-radius: 0 8px 8px 0;
  text-align: left;
}

thead tr th:last-child {
  text-align: left;
}

.user-img {
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 50%;
}

/* ------------------------account-page-admin----------------------- */
.heading-spaced {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 2px;
  color: #404040;
  margin-top: 40px;
}

.discount-details {
  padding: 16px 24px;
  border-radius: 8px;
  background: #f9f9f9;
  margin: 30px 0;
}

.card-heading {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  color: #404040;
}

.subscription-info-box {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
}

.subscription-info {
  border: 1px solid #d7d7d7;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 13px 16px;
  row-gap: 8px;
  white-space: nowrap;
  flex: 1 1 40%;
}

.subscription-info p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  color: #9f9f9f;
}

.subscription-info small {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
  color: #9f9f9f;
}

.continue-trial {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.continue-trial p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  color: #404040;
}

.buttons-cancel-update {
  display: flex;
  align-items: center !important;
  gap: 26px;
}

.cancel-btn {
  background: none;
  border: none;
  text-decoration: underline;
  color: #404040;
  font-size: 18px;
}

.payment-card:has(input[type="radio"]:checked) {
  border: 1px solid #ee7a3c;
  background: white;
}

.visa-card:has(input[type="radio"]:checked) {
  border: 1px solid #ee7a3c;
  background: white;
}

/* ---------------mobile nav----------- */
.navmob {
  display: none;
}

.reset-password-form {
  width: 650px;
  border: 1px solid #dfdfdf;
  padding: 40px;
  box-shadow: 2px 4px 20px #dfdfdf;
}

.center-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
}

#mainLoader {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
  text-align: center;
}

.mainLoader {
  position: fixed;
  z-index: 9999;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
}

span.upgrade-link.upgraded {
  color: #9f9f9f;
}

.ant-tabs {
  color: #fff !important;
}

/* --------------------------admin------------------------------- */
.main-page {
  padding: 20px 0;
}

.padding-admin {
  padding: 50px;
  background-color: #121212;
  min-height: 100vh;
}

.serachbar-box .searchbar {}

.serachbar-box .searchbar input.form-control {
  padding: 10px !important;
  background: #ffffff !important;
  outline: none !important;
  border-radius: 0px 10px 10px 0;
}

.serachbar-box .searchbar .input-group-text {
  border: none;
  border-radius: 10px 0 0 10px;
}

.top-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.sidebarprofile-sec img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 50%;
}

.input-date-bar input::-webkit-clear-button {
  display: none;
}

.page-name {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.page-name h4 {
  margin-bottom: 0;
}

.back-btn {
  background: white;
  border: none;
}

.import-export {
  display: flex;
  align-items: center;
  column-gap: 22px;
  width: fit-content;
}

.import-export button {
  color: #ee7a61;
  display: flex;
  border: none;
  background: white;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  column-gap: 8px;
}

.info-card {
  padding: 12px 18px;
  flex: 1 1 48%;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  display: block;
  column-gap: 25px;
}

.card-details .crypto {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.crypto h4 {
  margin-bottom: 0;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #fff;
  white-space: nowrap;
}

.heading-text {
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 16px;
}

.card-details .percentage {
  padding: 2px 6px;
  border-radius: 100px;
  background: #e7f7ed;
  color: #287648;
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.card-details a {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #ee7a61;
}

.balance {
  margin-top: 8px;
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0px;
  color: #404040;
}

.chart-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}

.period-box button,
.period-box .select {
  background: white;
  padding: 11px 14px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  color: #404040;
  transition: 0.4s ease-in-out;
  flex: 1 1 48%;
}

.period-box select {
  border: none;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  color: #404040;
  transition: 0.4s ease-in-out;
}

.chart {
  padding: 16px;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  margin: 20px 0;
}

.period-box {
  flex: 0 0 45%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
}

.period-box button {
  display: flex;
  align-items: center;
  column-gap: 14px;
  padding: 13px 14px !important;
}

.period-box button:hover,
.period-box .select:hover {
  background: #d7d7d7;
}

.period-box .select:hover select {
  background: #d7d7d7;
}

/* -------------------------users-------------------- */

.serachbar-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0;
  column-gap: 16px;
}

.searchbar {
  flex: 1 1 80%;
}

.filter {
  flex: 1 1 20%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 7px 10px;
  border-radius: 8px;
  border: 1px solid #d7d7d7;
  color: #9f9f9f;
  background-color: #ffffff;
  column-gap: 8px;
}

.filter select {
  width: 100%;
  border: none;
  color: #9f9f9f;
  font-family: "Termina Test", sans-serif;
}

.searchbar .input-group-text {
  background: white;
  font-size: 20px;
  color: #9f9f9f;
  border: 1px solid #dfdfdf;
  border-right: 0;
}

.searchbar .form-control {
  height: 46px;
  color: #9f9f9f;
  border-left: 0;
}

.user-details {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.padding-admin .filterbaradmin {
  gap: 20px;
  margin-bottom: 30px;
}

.padding-admin .filterbaradmin .btn {
  padding: 15px 20px !important;
  display: flex;
  align-items: center;
  gap: 5px;
}

.user-name {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
  color: #404040;
}

.user-mail {
  color: #9f9f9f;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
}

table thead {
  border-bottom: 10px solid transparent;
}

table tbody tr td {
  text-align: left;
  font-family: "Termina Test", sans-serif;
  padding: 6px 6px !important;
  word-wrap: break-word;
}

table td,
table th {
  vertical-align: middle;
}

table td {
  font-size: 14px;
  line-height: 20px;
  word-break: break-all;
  font-family: Graphik, sans-serif !important;
  font-weight: 500;
}

.ant-pagination li .ant-pagination-item-link,
.ant-pagination li a {
  background: #ffffff !important;
  color: #000000 !important;
  border-radius: 6px !important;
}

.ant-pagination li.ant-pagination-options .ant-pagination-options-quick-jumper {
  background: transparent !important;
  color: #ffffff !important;
}

.ant-pagination li.ant-pagination-item-active a {
  background: #ee7a3c !important;
  outline: none !important;
  box-shadow: none !important;
}

.ant-pagination li.ant-pagination-item-active {
  border-color: #ee7a3c !important;
}

table tr td {
  text-align: center;
}

table tr td:nth-child(2) {
  text-align: left;
}

tbody tr {
  background: #f9f9f9;
  border-bottom: 10px solid white;
  vertical-align: middle;
  height: 57px;
}

tbody tr td:first-child {
  border-radius: 8px 0 0 8px;
}

tbody tr td:last-child {
  border-radius: 0 8px 8px 0;
  text-align: left;
}

thead tr th:last-child {
  text-align: left;
}

.user-img {
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 50%;
}

img,
svg {
  vertical-align: middle;
  color: white;
}

/* ------------------------account-page-admin----------------------- */
.heading-spaced {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 2px;
  color: #404040;
  margin-top: 40px;
}

.discount-details {
  padding: 16px 24px;
  border-radius: 8px;
  background: #f9f9f9;
  margin: 30px 0;
}

.card-heading {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  color: #404040;
}

.subscription-info-box {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
}

.accountform .multiselect-bar {}

.accountform .multiselect-bar .multiSelectContainer {
  margin-bottom: 20px;
}

.accountform .multiselect-bar .multiSelectContainer .searchWrapper {
  border-radius: 5px;
  border: 2px solid #191a1d;
  background-color: #252628 !important;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.15) inset !important;
  padding: 18px 28px !important;
  min-height: 60px;
  font-family: Graphik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #ffffff;
  outline: none !important;
}

.accountform .multiselect-bar .multiSelectContainer ul.optionContainer {
  background: #212121 !important;
}

.reumeupload-form .multiSelectContainer ul {
  max-height: 150px !important;
}

.accountform .formninputbar label.form-label {
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0px !important;
  font-family: Graphik, sans-serif !important;
  margin: 0 0 10px 0;
  line-height: 1;
  display: block;
}

.accountform .multiselect-bar .multiSelectContainer ul.optionContainer .highlightOption {
  background: #d25029 !important;
}

.accountform .multiselect-bar .multiSelectContainer .searchWrapper .chip {
  background: #d25029 !important;
}

.accountform .multiselect-bar .multiSelectContainer .searchWrapper input#search_input {
  margin: 0px 0 0 10px !important;
  font-family: "Termina Test", sans-serif !important;
  color: #ffffff !important;
  font-size: 14px !important;
  cursor: pointer;
}

.accountform .multiselect-bar .multiSelectContainer .searchWrapper input#search_input::placeholder {
  color: #ffffff;
  cursor: pointer;
}

.accountform .formninputbar .form-control,
.accountform .formninputbar .filter {
  border-radius: 5px;
  border: 2px solid #191a1d;
  background-color: #252628 !important;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.15) inset !important;
  padding: 16px 28px !important;
  min-height: 60px;
  font-family: Graphik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #ffffff;
  outline: none !important;
}

.accountform .formninputbar .filter select {
  background: transparent !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

table.table {
  display: table;
  overflow-x: auto;
  white-space: nowrap;
}

.subscription-info {
  border: 1px solid #d7d7d7;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 13px 16px;
  row-gap: 8px;
  white-space: nowrap;
  flex: 1 1 40%;
}

.subscription-info p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  color: #9f9f9f;
}

.subscription-info small {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
  color: #9f9f9f;
}

.continue-trial {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.continue-trial p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  color: #404040;
}

.buttons-cancel-update {
  display: flex;
  align-items: center !important;
  gap: 26px;
}

.cancel-btn {
  background: none;
  border: none;
  text-decoration: underline;
  color: #404040;
  font-size: 18px;
}

.payment-card:has(input[type="radio"]:checked) {
  border: 1px solid #ee7a3c;
  background: white;
}

.visa-card:has(input[type="radio"]:checked) {
  border: 1px solid #ee7a3c;
  background: white;
}

/* ---------------mobile nav----------- */
.navmob {
  display: none;
}

.reset-password-form {
  width: 650px;
  border: 1px solid #dfdfdf;
  padding: 40px;
  box-shadow: 2px 4px 20px #dfdfdf;
}

.center-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
}

#mainLoader {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
  text-align: center;
}

.mainLoader {
  position: fixed;
  z-index: 9999;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
}

span.upgrade-link.upgraded {
  color: #9f9f9f;
}

.DashboardPageLayout {
  position: relative;
}

.DashboardPageLayout .Sidebarlayout {
  position: fixed;
  top: 0;
  left: 0;
  width: 270px;
  height: 100dvh;
  border-right: 1px solid #f1f1f1;
  padding: 20px;
  background-color: var(--black);
  transition: all 0.4s ease-in-out;
  overflow: auto;
}

.DashboardPageLayout .Sidebarlayout .navbarbrand {
  margin-bottom: 40px;
}

.sidebarprofile-sec h5.username {
  margin: 12px 0 0 0;
  font-size: 18px;
  font-weight: 600;
  word-wrap: break-word;
}

.sidebarprofile-sec {
  margin-bottom: 50px;
}

.Sidebarlayout .mainnavbar {
  display: block;
}

.Sidebarlayout .mainnavbar .navbar-title {
  color: #9f9f9f;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  word-wrap: break-word;
  margin-bottom: 34px;
}

table.table.table-hover {
  table-layout: fixed;
}

table a {
  color: #181818;
}

/* .Sidebarlayout .logoutbar {
      position: absolute;
      bottom: 20px;
      width: 100%;
      left: 0;
      padding: 0 50px;
      height: auto;
  } */
.logoutbar {
  margin-top: 0px;
}

/* ::-webkit-scrollbar{
      width: 10px;
      background-color: var(--base02);
  }
  ::-webkit-scrollbar-button{
      width: 10px;
      height: 10px;
      background-color: red;
  } */

.allblogs .blogtab {
  margin-bottom: 100px;
  border: none;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 50px;
  gap: 15px;
}

.Sidebarlayout .mainnavbar .navbar-nav {
  display: block;
}

.Sidebarlayout .mainnavbar a {
  color: #9f9f9f;
  font-size: 18px;
  font-weight: 500;

  word-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  margin-bottom: 24px;
  transition: 0.3s ease;
}

.mainwrapper {
  min-height: 100vh;
  background: var(--white);

  padding-left: 270px;
}

.Sidebarlayout .mainnavbar .navbar-nav a.active,
.Sidebarlayout .mainnavbar a:hover {
  color: var(--base02);
}

.bodywrapper {
  padding: 40px;
  max-width: 820px;
}

.Sidebarlayout .mainnavbar a svg {
  font-size: 24px;
}

.title-with-backbtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 15px;
  margin-bottom: 50px;
}

.backbtn {
  border: none;
  padding: 0;
  background: transparent;
  font-size: 30px;
}

.title-with-backbtn .backbtn svg {
  color: #ffffff;
}

.title-with-backbtn h4 {
  margin: 0;
  color: var(--white);
  font-size: 22px;
  font-weight: 600;
  word-wrap: break-word;
}

.profile-picture-bar {
  text-align: center;
  margin-bottom: 40px;
}

.profile-image-box {
  max-width: 100px;
  margin: 0 auto;
  position: relative;
}

.profile-image-box .imageuplaod-area img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 50%;
}

.profile-image-box .plusbtn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primarygradient);
  border-radius: 50px;
  color: #ffffff;
  position: absolute;
  right: 5px;
  bottom: 5px;
  transform: translate(0px, 0px);
  cursor: pointer;
  z-index: 2;
  overflow: hidden;
}

.profile-image-box .plusbtn input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.ant-tabs .ant-tabs-tab {
  color: #fff !important;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ef6230 !important;
}

.profile-image-box .imageuplaod-area {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: 1px solid #f1f1f1;
  width: 100px;
  height: 100px;
}

.Accountbar form {
  position: relative;
}

/* The custom-radio-box */
.custom-radio-box {
  display: block;
  position: relative;
  padding-left: 0;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.form-radio-bar {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.custom-radio-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50px;
}

.custom-radio-box .checkmark {
  min-width: 202px;
  height: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 16px;
  color: var(--border);
  border: 1px solid var(--border);
  text-transform: capitalize;
}

.custom-radio-box input:checked~.checkmark {
  border-color: var(--base00);
  color: var(--base00);
}

/* Show the indicator (dot/circle) when checked */

/* Style the indicator (dot/circle) */
.custom-radio-box .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.formninputbar {
  position: relative;
  margin-bottom: 20px;
}

.formninputbar .form-label {
  color: #9f9f9f;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word;
}

.input-date-bar .react-datepicker-wrapper {
  width: 100%;
  position: relative;
}

.input-date-bar span.input-group-text {
  position: absolute;
  right: 0;
  padding: 0;
  background: transparent;
  border: none;
  top: 50%;
  right: 10px;
  transform: translate(0px, -50%);
  z-index: 1;
}

.input-date-bar .react-datepicker-wrapper input.form-control {
  padding-right: 40px;
  color: #9f9f9f;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  height: 50px;
  position: relative;
  z-index: 2;
  background: transparent;
}

.inputbar-btnpink {
  margin-top: 50px;
}

.jobdetail-accordion .accordion {
  gap: 20px;
  display: flex;
  align-items: flex-start;
}

.jobdetail-accordion .accordion .accordion-item {
  flex: 0 0 50%;
  max-width: 50%;
  border-radius: 22.694px;
  border: 1.135px solid #333;
  background: linear-gradient(180deg, #2f2f31 0%, #1d1e20 100%);
  box-shadow: 0px 15.886px 45.388px 0px rgba(0, 0, 0, 0.35);
}

.jobdetail-accordion {
  margin-bottom: 100px;
}

.jobdetail-accordion .accordion .accordion-item .accordion-button {
  border-radius: 22.694px;
  border: 1.135px solid #333;
  background: linear-gradient(180deg, #2f2f31 0%, #1d1e20 100%);
  box-shadow: 0px 15.886px 45.388px 0px rgba(0, 0, 0, 0.35);
  border-bottom: 0px;
  color: #fff;
  font-family: "Termina Test", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 40px 40px;
}

.jobdetail-accordion .accordion .accordion-item .accordion-button::after {
  stroke-width: 1.135px;
  stroke: #333;
  filter: drop-shadow(0px 15.886px 45.388px rgba(0, 0, 0, 0.51));
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><path d="M15 6.25L9 12.25L3 6.25" stroke="white" stroke-width="2" stroke-linecap="square"/></svg>');
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  border: 1px solid #333333;
  border-radius: 50%;
  background-color: #2f2f31;
}

.jobdetail-accordion .accordion .accordion-item .accordion-body {
  padding: 40px;
  color: rgba(255, 255, 255, 0.8);
  font-family: Graphik, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  /* 200% */
  letter-spacing: 0.2px;
}

.inputbar-radiocheck .form-label {
  color: #404040;
}

.jobbanner {
  background-image: url("../../public/images/pattern.webp");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.formtitle {
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0px !important;
  font-family: Graphik, sans-serif !important;
  margin: 0 0 10px 0;
}

.aboutform .formtitle {
  margin-bottom: 50px;
}

.form-toolbar {
  padding: 14px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f9f9f9;
  border-radius: 12px;
  margin-bottom: 16px;
}

.form-toolbar h4 {
  margin: 0;
  color: #404040;
  font-size: 16px;
  font-weight: 500;
  word-wrap: break-word;
}

.form-toolbar ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 16px;
}

.form-toolbar ul button {
  background: white;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.07);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: var(--base00);
}

.form-toolbar ul button.active {
  background: linear-gradient(90deg, #e7407d 0%, #ef805f 100%);
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.07);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #ffffff;
}

.form-secondry-title {
  color: #404040;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
}

.formninputbar .textgray {
  color: #9f9f9f;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 5px;
}

.formninputbar textarea {
  min-height: 90px;
  background: #f1f1f1;
}

.loader {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(244, 244, 244, 0);
  border-radius: 4px;
}

input[type="file"]::file-selector-button {
  font-size: 14px;
  background: linear-gradient(180deg, #fa6800 0%, #cc4b00 100%);
  color: #ffffff;
  border: 1px solid #cc4b00;
  border-radius: 8px;
}

[data-custom-class="body"],
[data-custom-class="body"] * {
  background: transparent !important;
}

[data-custom-class="title"],
[data-custom-class="title"] * {
  font-family: Arial !important;
  font-size: 26px !important;
  color: #ffffff !important;
}

[data-custom-class="subtitle"],
[data-custom-class="subtitle"] * {
  font-family: Arial !important;
  color: #ffffff !important;
  font-size: 14px !important;
}

[data-custom-class="heading_1"],
[data-custom-class="heading_1"] * {
  font-family: Arial !important;
  font-size: 19px !important;
  color: #ffffff !important;
}

[data-custom-class="heading_2"],
[data-custom-class="heading_2"] * {
  font-family: Arial !important;
  font-size: 17px !important;
  color: #ffffff !important;
}

[data-custom-class="body_text"],
[data-custom-class="body_text"] * {
  color: #ffffff !important;
  font-size: 14px !important;
  font-family: Arial !important;
}

[data-custom-class="link"],
[data-custom-class="link"] * {
  color: #fd613d !important;
  font-size: 14px !important;
  font-family: Arial !important;
  word-break: break-word !important;
}

.mobile-togglemenu .navbar-nav {
  display: none !important;
}

.blog-content p:first-child {
  border-left: 1px solid #ff4012;
  padding-left: 25px;
}

.whyapply .rec-carousel-item .rec {}

.language-accords button.accordion-button {
  background: #222;
  color: #fff;
  box-shadow: none;
}

.language-accords .accordion-item {
  border-color: #333;
  margin-bottom: 10px;
  border-radius: 10px !important;
  overflow: hidden;
}

.language-accords .accordion-header {
  background: #222;
}

.language-accords .accordion-body {
  background: #111;
}

.language-accords .accordion-body input,
.language-accords .accordion-body textarea {
  border: 1px solid #333;
  padding: 12px !important;
  font-size: 14px !important;
  color: #fff !important;
}

.rdw-editor-wrapper {
  background: #252628;
  margin-bottom: 25px;
}

.rdw-editor-main {
  padding: 0 15px;
  min-height: 200px;
}

.rdw-editor-toolbar {
  background: #333 !important;
  border-color: #333 !important;
}

.rdw-option-wrapper {
  background: #111 !important;
  border-color: #111 !important;
  color: #fff !important;
  padding: 11px !important;
}

.rdw-option-wrapper img {
  filter: brightness(0) invert(1) !important;
}

.rdw-dropdown-wrapper {
  background: #111 !important;
  border-color: #111 !important;
}

.JobDetail-block span {
  color: #fff !important;
  background: transparent !important;
}

.frontend-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.frontend-pagination a,
.frontend-pagination span {
  background: #000 !important;
  color: #fff !important;
}

.frontend-pagination .ant-pagination li .ant-pagination-item-link,
.frontend-pagination .ant-pagination li a {
  background: #000 !important;
  color: #fff !important;
  border-radius: 6px !important;
  border: none !important;
}

.frontend-pagination .ant-select-selector {
  background: #000 !important;
  border: none !important;
}

/* .frontend-pagination .ant-pagination-options-quick-jumper,
.frontend-pagination .ant-pagination-options{
  display: none !important;
} */

.frontend-pagination .ant-pagination-options-quick-jumper input {
  background: #000 !important;
  color: #fff !important;
  border: none !important;
}

.frontend-pagination .ant-pagination li.ant-pagination-item-active .ant-pagination-item-link,
.frontend-pagination .ant-pagination li.ant-pagination-item-active a {
  background: #D75200 !important;
  color: #fff !important;
}

.info-card-big .card-content {
  width: 50% !important;
}

.info-card-big .crypto {
  padding-top: 7px;
}

.applicationcontinue-section {
  padding-top: 120px;
  padding-bottom: 100px;
}

.not-found-page-section {
  text-align: center;
  padding-top: 200px;
  padding-bottom: 100px;
}

.btn-sm {
  padding: 5px 15px;
  font-size: 14px;
}

.theme-form-control {
  padding: 16px 20px !important;
  color: #fff;
  background: #252628 !important;
  border-radius: 0;
  border: 2px solid #191a1d;
  min-height: 60px;
  font-family: Graphik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
}

.theme-form-control:active,
.theme-form-control:focus {
  outline: none;
}

.form-help-link,
.form-help-link:hover {
  color: #F96701 !important;
}

.react-select__control {
  background: #232426 !important;
  border: none !important;
}

.accountform .formninputbar .react-select-container.form-control {
  padding: 10px !important;
}

.react-select__multi-value {
  background: #F96701 !important;
}

.react-select__multi-value__label {
  color: #fff !important;
}

.react-select__input {
  color: #fff !important;
}

.react-select--is-disabled {
  opacity: 0.7;
}

.formstepsbig {
  max-width: 800px !important;
}

.formsteps-back {
  text-align: center;
  margin-top: 35px;
  margin-bottom: -35px;
  text-decoration: underline;
  opacity: 0.75;
}

.formsteps-back:hover {
  opacity: 1;
}

.formsteps-back:hover a {
  color: #fff !important;
}

.empty-box {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexbar-4 {
  grid-template-columns: 25% 25% 25% 25% !important;
  column-gap: 0 !important;
  gap: 20px !important;
  place-items: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
}

.flexbar-4 .selectbox {
  margin: 0;
  width: 100%;
  padding: 0;
  height: 100%;
}

.flexbar-4 p {
  margin: 0;
  color: #858585;
  text-align: center;
  text-shadow: 0px 0px 4.182px rgba(0, 0, 0, 0.8);
  font-family: Graphik, sans-serif;
  font-size: 22.302px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.089px;
}

.center-captcha {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loader p {
  margin-top: 10px;
}

.quick-apply-field-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  gap: 10px;
}

.badge-primary {
  border-radius: 100px;
  background: linear-gradient(180deg, #fa6800 0%, #cc4b00 100%);
  box-shadow: 0px 4px 31px 0px rgba(0, 0, 0, 0.15);
  border: none;
  color: #fff;
  cursor: pointer;
}

.badge-primary:hover {
  color: #fff;
}

.normal-tb-txt {
  font-size: 14px;
  font-weight: 500 !important;
}

.table-actions {
  display: flex;
}

.table-select-status {
  background: #fff;
  border: 1px solid #333 !important;
  color: #333 !important;
  padding: 5px 10px !important;
  border-radius: 15px !important;
  width: 160px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.table-actions button {
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-left: 5px !important;
}

.modal-block-bar .block-bar {
  padding: 0 !important;
  background: transparent !important;
  background-image: none !important;
  box-shadow: none !important;
}

.modal-block-bar .btnform {
  margin-top: 0 !important;
  column-gap: 10px !important;
}

.email-tag-variables{
  display: flex;
  gap: 10px;
}

.email-tag-variables span{
  font-size: 14px;
  cursor: default;
  font-weight: 500 !important;
  font-family: 'Graphik', sans-serif !important;
}

@media screen and (min-width: 767px) {
  .modal-dialog.modal-lg {
    max-width: 800px !important;
  }
}