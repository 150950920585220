@font-face {
    font-family: 'Termina Test Extra';
    src: url('Termina-ExtraLight.eot');
    src: url('Termina-ExtraLight.eot') format('embedded-opentype'),
        url('Termina-ExtraLight.woff2') format('woff2'),
        url('Termina-ExtraLight.woff') format('woff'),
        url('Termina-ExtraLight.ttf') format('truetype'),
        url('Termina-ExtraLight.svg#Termina-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Termina Test';
    src: url('Termina-Thin.eot');
    src: url('Termina-Thin.eot?#iefix') format('embedded-opentype'),
        url('Termina-Thin.woff2') format('woff2'),
        url('Termina-Thin.woff') format('woff'),
        url('Termina-Thin.ttf') format('truetype'),
        url('Termina-Thin.svg#Termina-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Termina Test';
    src: url('Termina-Regular.eot');
    src: url('Termina-Regular.eot?#iefix') format('embedded-opentype'),
        url('Termina-Regular.woff2') format('woff2'),
        url('Termina-Regular.woff') format('woff'),
        url('Termina-Regular.ttf') format('truetype'),
        url('Termina-Regular.svg#Termina-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Termina Test';
    src: url('Termina-Light.eot');
    src: url('Termina-Light.eot?#iefix') format('embedded-opentype'),
        url('Termina-Light.woff2') format('woff2'),
        url('Termina-Light.woff') format('woff'),
        url('Termina-Light.ttf') format('truetype'),
        url('Termina-Light.svg#Termina-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Termina Test';
    src: url('Termina-Heavy.eot');
    src: url('Termina-Heavy.eot?#iefix') format('embedded-opentype'),
        url('Termina-Heavy.woff2') format('woff2'),
        url('Termina-Heavy.woff') format('woff'),
        url('Termina-Heavy.ttf') format('truetype'),
        url('Termina-Heavy.svg#Termina-Heavy') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Termina Test';
    src: url('Termina-Bold.eot');
    src: url('Termina-Bold.eot?#iefix') format('embedded-opentype'),
        url('Termina-Bold.woff2') format('woff2'),
        url('Termina-Bold.woff') format('woff'),
        url('Termina-Bold.ttf') format('truetype'),
        url('Termina-Bold.svg#Termina-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Termina Test';
    src: url('Termina-Medium.eot');
    src: url('Termina-Medium.eot?#iefix') format('embedded-opentype'),
        url('Termina-Medium.woff2') format('woff2'),
        url('Termina-Medium.woff') format('woff'),
        url('Termina-Medium.ttf') format('truetype'),
        url('Termina-Medium.svg#Termina-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Termina Test Demi';
    src: url('Termina-Demi.eot');
    src: url('Termina-Demi.eot?#iefix') format('embedded-opentype'),
        url('Termina-Demi.woff2') format('woff2'),
        url('Termina-Demi.woff') format('woff'),
        url('Termina-Demi.ttf') format('truetype'),
        url('Termina-Demi.svg#Termina-Demi') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Termina Test';
    src: url('Termina-Black.eot');
    src: url('Termina-Black.eot?#iefix') format('embedded-opentype'),
        url('Termina-Black.woff2') format('woff2'),
        url('Termina-Black.woff') format('woff'),
        url('Termina-Black.ttf') format('truetype'),
        url('Termina-Black.svg#Termina-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('Graphik-MediumItalic.eot');
    src: url('Graphik-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Graphik-MediumItalic.woff2') format('woff2'),
        url('Graphik-MediumItalic.woff') format('woff'),
        url('Graphik-MediumItalic.ttf') format('truetype'),
        url('Graphik-MediumItalic.svg#Graphik-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('Graphik-Light.eot');
    src: url('Graphik-Light.eot?#iefix') format('embedded-opentype'),
        url('Graphik-Light.woff2') format('woff2'),
        url('Graphik-Light.woff') format('woff'),
        url('Graphik-Light.ttf') format('truetype'),
        url('Graphik-Light.svg#Graphik-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('Graphik-LightItalic.eot');
    src: url('Graphik-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Graphik-LightItalic.woff2') format('woff2'),
        url('Graphik-LightItalic.woff') format('woff'),
        url('Graphik-LightItalic.ttf') format('truetype'),
        url('Graphik-LightItalic.svg#Graphik-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('Graphik-Regular.eot');
    src: url('Graphik-Regular.eot?#iefix') format('embedded-opentype'),
        url('Graphik-Regular.woff2') format('woff2'),
        url('Graphik-Regular.woff') format('woff'),
        url('Graphik-Regular.ttf') format('truetype'),
        url('Graphik-Regular.svg#Graphik-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('Graphik-ThinItalic.eot');
    src: url('Graphik-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('Graphik-ThinItalic.woff2') format('woff2'),
        url('Graphik-ThinItalic.woff') format('woff'),
        url('Graphik-ThinItalic.ttf') format('truetype'),
        url('Graphik-ThinItalic.svg#Graphik-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('Graphik-SuperItalic.eot');
    src: url('Graphik-SuperItalic.eot?#iefix') format('embedded-opentype'),
        url('Graphik-SuperItalic.woff2') format('woff2'),
        url('Graphik-SuperItalic.woff') format('woff'),
        url('Graphik-SuperItalic.ttf') format('truetype'),
        url('Graphik-SuperItalic.svg#Graphik-SuperItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('Graphik-Medium.eot');
    src: url('Graphik-Medium.eot?#iefix') format('embedded-opentype'),
        url('Graphik-Medium.woff2') format('woff2'),
        url('Graphik-Medium.woff') format('woff'),
        url('Graphik-Medium.ttf') format('truetype'),
        url('Graphik-Medium.svg#Graphik-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('Graphik-RegularItalic.eot');
    src: url('Graphik-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('Graphik-RegularItalic.woff2') format('woff2'),
        url('Graphik-RegularItalic.woff') format('woff'),
        url('Graphik-RegularItalic.ttf') format('truetype'),
        url('Graphik-RegularItalic.svg#Graphik-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('Graphik-SemiboldItalic.eot');
    src: url('Graphik-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('Graphik-SemiboldItalic.woff2') format('woff2'),
        url('Graphik-SemiboldItalic.woff') format('woff'),
        url('Graphik-SemiboldItalic.ttf') format('truetype'),
        url('Graphik-SemiboldItalic.svg#Graphik-SemiboldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('Graphik-Super.eot');
    src: url('Graphik-Super.eot?#iefix') format('embedded-opentype'),
        url('Graphik-Super.woff2') format('woff2'),
        url('Graphik-Super.woff') format('woff'),
        url('Graphik-Super.ttf') format('truetype'),
        url('Graphik-Super.svg#Graphik-Super') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('Graphik-Thin.eot');
    src: url('Graphik-Thin.eot?#iefix') format('embedded-opentype'),
        url('Graphik-Thin.woff2') format('woff2'),
        url('Graphik-Thin.woff') format('woff'),
        url('Graphik-Thin.ttf') format('truetype'),
        url('Graphik-Thin.svg#Graphik-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('Graphik-Semibold.eot');
    src: url('Graphik-Semibold.eot?#iefix') format('embedded-opentype'),
        url('Graphik-Semibold.woff2') format('woff2'),
        url('Graphik-Semibold.woff') format('woff'),
        url('Graphik-Semibold.ttf') format('truetype'),
        url('Graphik-Semibold.svg#Graphik-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('Graphik-Bold.eot');
    src: url('Graphik-Bold.eot?#iefix') format('embedded-opentype'),
        url('Graphik-Bold.woff2') format('woff2'),
        url('Graphik-Bold.woff') format('woff'),
        url('Graphik-Bold.ttf') format('truetype'),
        url('Graphik-Bold.svg#Graphik-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('Graphik-BoldItalic.eot');
    src: url('Graphik-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Graphik-BoldItalic.woff2') format('woff2'),
        url('Graphik-BoldItalic.woff') format('woff'),
        url('Graphik-BoldItalic.ttf') format('truetype'),
        url('Graphik-BoldItalic.svg#Graphik-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('Graphik-Black.eot');
    src: url('Graphik-Black.eot?#iefix') format('embedded-opentype'),
        url('Graphik-Black.woff2') format('woff2'),
        url('Graphik-Black.woff') format('woff'),
        url('Graphik-Black.ttf') format('truetype'),
        url('Graphik-Black.svg#Graphik-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('Graphik-ExtralightItalic.eot');
    src: url('Graphik-ExtralightItalic.eot?#iefix') format('embedded-opentype'),
        url('Graphik-ExtralightItalic.woff2') format('woff2'),
        url('Graphik-ExtralightItalic.woff') format('woff'),
        url('Graphik-ExtralightItalic.ttf') format('truetype'),
        url('Graphik-ExtralightItalic.svg#Graphik-ExtralightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('Graphik-Extralight.eot');
    src: url('Graphik-Extralight.eot?#iefix') format('embedded-opentype'),
        url('Graphik-Extralight.woff2') format('woff2'),
        url('Graphik-Extralight.woff') format('woff'),
        url('Graphik-Extralight.ttf') format('truetype'),
        url('Graphik-Extralight.svg#Graphik-Extralight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('Graphik-BlackItalic.eot');
    src: url('Graphik-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('Graphik-BlackItalic.woff2') format('woff2'),
        url('Graphik-BlackItalic.woff') format('woff'),
        url('Graphik-BlackItalic.ttf') format('truetype'),
        url('Graphik-BlackItalic.svg#Graphik-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

