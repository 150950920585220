@media (min-width: 1600px) {
  .whyapply .rec-carousel-item .rec {
    width: 435px !important;
  }
}

@media (max-width: 1599px) {
  .container {
    max-width: 1270px;
  }
  .banner h1 {
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 20px;
  }

  .banner p {
    max-width: 700px;
    margin-bottom: 40px;
  }
  .whyapply .rec-carousel-item .rec-item-wrapper .slidebox h4 {
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 10px;
  }

  .whyapply .rec-carousel-item .rec-item-wrapper .slidebox {
    padding: 40px 30px !important;
    min-height: 340px;
  }

  .whyapply .rec-carousel-item .rec-item-wrapper .slidebox p {
    font-size: 15px;
    line-height: 22px;
  }

  .whyapply .rec-carousel-item .rec-item-wrapper .slidebox img {
    width: 70px;
    height: 70px;
    margin-bottom: 20px;
  }

  .whyapply .btngroup {
    margin-top: 50px;
  }

  .whyapply {
    padding: 0 0 80px 0;
  }

  .KeyNumber {
    padding: 80px 0;
  }

  .lookingservice .service-content h4 {
    font-size: 18px;
    line-height: 22px;
  }

  .LookingFor {
    padding: 80px 0;
  }

  .CareerOpportunitydivider {
    padding: 80px 0;
  }

  .CareerOpportunitydivider .container h4 {
    font-size: 32px;
  }

  .let-talk-block .letstalk-inner {
    padding: 70px 50px !important;
  }

  .let-talk-block .letstalk-inner h3 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 30px;
  }

  .footerbar {
    padding: 80px 0 0 0;
  }

  .Copyrightbar {
    padding: 60px 0 30px 0;
  }
}

@media (max-width: 1440px) {
}

@media (max-width: 1299px) {
  .whyapply {
    padding: 0 0 70px 0;
    overflow: hidden;
  }
  .home-wrapper .homebar .banner {
    height: auto;
    padding: 150px 0;
  }
  .whyapply .rec-carousel .rec-arrow {
    position: absolute;
    left: 40px;
  }
  .whyapply .rec-carousel .rec-arrow.rec-arrow-right {
    right: 40px !important;
    left: inherit !important;
  }
  .KeyNumber {
    padding: 80px 0;
  }
}

@media (max-width: 1024px) {
  .KeyNumber {
    padding: 70px 0;
  }
}

@media (max-width: 991px) {
  .pie-parent,
  .piebar {
    width: 180px;
    height: 180px;
  }
  .navbar-expand-lg .mobile-togglemenu.navbar-collapse.show {
    display: flex !important;
    padding: 20px;
  }
  .custom-top {
    position: relative;
    top: 0;
  }
  .countNumber {
    padding-bottom: 0px;
    padding-top: 0px;
  }

  .jobdetail-accordion .accordion {
    flex-direction: column;
  }

  .uploadresumeblock .reumeupload-form {
    grid-template-columns: 100%;
  }
  .jobdetail-accordion .accordion .accordion-item {
    max-width: 100%;
    flex: 0 0 100%;
    width: 100%;
  }
  .jobdetail-accordion {
    margin-bottom: 60px;
  }
  .jobdetail-accordion .accordion .accordion-item .accordion-body {
    font-size: 14px;
    padding: 15px;
  }
  .jobdetail-accordion .accordion .accordion-item .accordion-button {
    font-size: 18px;
    padding: 10px 15px;
  }
  .arrow-dropdown {
    appearance: auto;
    padding: 0 0 16px 0 !important;
  }
  .applyjob-conditions {
    padding: 40px 0 40px 0;
  }
  .jobdetail-accordion .accordion .accordion-item .accordion-button::after {
    width: 30px;
    height: 30px;
    background-size: 12px;
  }
  .lookingservice .service-content h4 {
    font-size: 16px;
    line-height: 20px;
  }
  .lookingservice .carside img {
    width: 60px;
  }
  .gridbar {
    grid-template-columns: auto auto;
  }
  .rightbar-nav {
    display: none;
  }
  .MainHeader .navbar-primary .navbar-collapse .rightbar-nav {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 0px 20px 0px;
    gap: 20px;
  }
  .MainHeader.sticky-header .navbar-primary .rightbar-nav {
    display: none;
  }
  .desktop-menu-bar {
    display: none !important;
  }
  .mobile-log-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .mobile-togglemenu {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 0px;
    background-image: url("../../public/images/pattern.webp");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .mobile-logo {
    width: 60%;
  }
  .cross-icon {
    width: 40%;
    text-align: right;
  }
  .cross-icon img {
    width: 25px;
    height: 25px;
  }
  .mobile-menu-bar.navbar-nav {
    display: flex !important;
  }
  .btnwhite {
    border: 1.171px solid var(--white);
    background: transparent;
  }
  .btn {
    padding: 14px 50px;
    border-radius: 100px;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.2px;
    color: var(--white);
    transition: 0.4s ease;
    font-family: "Termina Test";
  }
  .MainHeader .navbar-primary {
    padding: 20px 0;
  }
  .MainHeader .navbar-primary .navbar-toggler {
    background: #ffffff;
  }
  .MainHeader.sticky-header .navbar-primary {
    padding: 15px 0;
  }
  .MainHeader .navbar-primary .navbar-nav a {
    padding: 10px 0;
    text-align: left;
    font-size: 40px;
    font-weight: 600;
    line-height: 56px;
  }

  .MainHeader .navbar-primary .navbar-nav a:last-child {
    border: none;
  }

  .MainHeader .navbar-primary .navbar-nav {
    padding: 0px 0px;
    /* background: #181717; */
  }
  .home-wrapper .homebar .banner {
    height: auto;
    padding: 150px 0 100px 0;
  }
  .banner h1 {
    font-size: 40px;
    line-height: 52px;
  }
  .cross-icon img {
    filter: invert(1);
    opacity: 1;
  }
  .home-wrapper .homebar .banner .bar-line {
    margin-bottom: 30px;
  }
  .banner p {
    font-size: 18px;
    line-height: 24px;
  }
  .MainHeader .navbar-primary a.navbar-brand {
    width: 60%;
  }
  .MainHeader .navbar-primary .navbar-toggler {
    padding: 0;
    background-image: url("../../public/images/menu-humberger.png") !important;
    background-color: transparent !important;
    max-width: 70px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 25px;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
    filter: invert(1) brightness(2.5);
    opacity: 1;
  }
  .MainHeader .navbar-primary .navbar-toggler span.navbar-toggler-icon {
    display: none;
  }
  .MainHeader .navbar-primary .navbar-toggler span.navbar-toggler-icon {
    width: 24px;
    height: 24px;
  }
  .primary-title h2 {
    font-size: 28px;
    line-height: 32px;
  }
  .primary-title {
    margin-bottom: 30px;
  }
  .whyapply .btngroup {
    margin-top: 30px;
  }
  .whyapply .btngroup .btn {
    width: auto;
    margin-bottom: 15px;
  }
  .CareerOpportunitydivider .container {
    flex-direction: column;
    gap: 20px;
  }
  .quick-block {
    padding: 50px 0 0px 0;
  }
  .step-form-container .block-bar form {
    margin-top: 50px;
  }
  .step-form-container .block-bar .btnform {
    margin-top: 50px;
  }
  .step-form-container.step-3 .formsteps .flexbar {
    grid-template-columns: auto auto auto;
  }
  .let-talk-block {
    padding: 50px 0;
  }
  .footerbar {
    padding: 50px 0 0px 0;
  }
  .footernavbar {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    column-gap: 50px;
    max-width: 100%;
  }
  .footerbar .foot-logobar {
    text-align: center;
    margin-bottom: 30px;
  }
  .footerleft {
    margin-bottom: 30px;
    text-align: center;
  }
  .aboutcontent {
    padding: 50px 0 50px 0;
  }
  .aboutleft-img {
    height: 400px;
    overflow: hidden;
    width: 100%;
    margin-bottom: 30px;
  }
  .aboutleft-img img {
    width: 100%;
    object-fit: cover;
  }
  .aboutcontent .about-rytside .welder-img {
    margin-top: 30px;
  }
  .Aboutbar .whyapply {
    padding: 30px 0 50px 0;
  }
  .listingbar {
    padding: 20px 30px;
  }
  .listingbar .topbar-title {
    column-gap: 20px;
    margin-bottom: 20px;
  }
  .about-listing {
    padding: 50px 0;
  }
  .articleblog-block {
    padding: 0 0 50px 0;
  }
  .allblogs .blogtab {
    margin-bottom: 50px;
    border: none;
    flex-wrap: nowrap;
    gap: 10px;
    padding-bottom: 50px;
    overflow-x: scroll;
  }
  ::-webkit-scrollbar {
    background-color: rgb(255 255 255 / 24%);
    height: 1px;
  }
  .allblogs .blogtab li.nav-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .allblogs .blogtab .nav-link.disabled {
    width: 170px;
  }
  .bloglisting {
    column-gap: 20px;
    grid-template-columns: auto auto;
  }
  .articles-block .row {
    gap: 30px;
  }
  .filterbar .filterrow {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
  .searchbar {
    position: relative;
    width: 100%;
  }
  .joblistingbar {
    padding: 30px 24px;
    gap: 30px;
    grid-template-columns: auto auto auto;
  }
  .joblistingbar .jobtitle {
    font-size: 18px;
    line-height: 22px;
  }
  .alljobs-listing {
    padding: 40px 0 10px 0;
  }
  .let-talk-block {
    padding: 50px 0;
  }
  .contact-banner {
    padding: 120px 0 50px 0;
  }
  .contactformbar {
    padding: 70px 0;
  }
  .whyapply .rec-carousel-item .rec {
    width: 724px !important;
  }
  .linebarbox {
    margin-top: 00px;
  }
  .emptybar .row50 {
    display: none !important;
  }
  .landlord-info-banner .bannerorw .row50 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .step-form-container.step-3 .formsteps {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .KeyNumber .row {
    margin: 0;
  }

  .KeyNumber .row .countNumber {
    height: 100% !important;
  }

  .countNumber h3 {
    font-size: 32px;
  }

  .countNumber h5 {
    font-size: 14px;
    line-height: 22px;
    margin: 10px 0 0 0 !important;
  }

  .onecard,
  .threecard {
    margin-bottom: 30px !important;
  }
  .pie-parent {
    width: 150px;
    height: 150px;
  }
  .piebar {
    width: auto;
    height: auto;
  }
  .KeyNumber .row .twocard {
    order: 3;
  }
  .countNumber {
    display: block;
  }
  .cookie-consent-bar .btngroupbar {
    justify-content: center;
    justify-items: self-end;
  }
  .language-moblie {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    gap: 10px;
  }
  .lang-mobile {
    display: block;
  }
  .language-moblie li button {
  }
  .linebarbox h5 {
  }
  .cookie-consent-bar .btn {
    padding: 10px 20px;
  }
  .darkmode-modal .btnwhite {
    margin-top: 20px;
  }
  .whyapply .rec.rec-swipable {
    display: grid !important;
    gap: 20px;
  }

  .whyapply .rec-carousel .rec-slider-container {
    margin: 0 !important;
  }

  .whyapply .rec-carousel .rec-slider-container .rec-slider {
    top: inherit !important;
    right: inherit !important;
    left: inherit !important;
  }

  .whyapply .rec-carousel .rec-arrow.rec-arrow-right,
  .whyapply .rec-carousel .rec-arrow.rec-arrow-left {
    display: none !important;
  }
  .whyapply .btngroup {
    margin-top: 30px;
    padding: 0 50px;
  }
  .primary-title.title-leftside {
    text-align: center;
  }
  .primary-title.title-leftside .bar-line {
    margin: 0 auto;
  }
  .whyapply .btngroup .btn {
    width: max-content;
    margin-bottom: 15px;
  }
  .banner h1 {
    font-size: 38px;
    line-height: 45.6px;
  }
  .home-wrapper .homebar .banner {
    height: auto;
    padding: 150px 0 70px 0;
  }
  .cookie-consent-bar {
    width: 90%;
    left: 0;
    margin: 0 auto;
    border-radius: 20px 20px 0 0;
    padding: 15px;
    text-align: center;
  }
  .step-form-container.step-3 .formsteps {
    margin: 0 auto;
    max-width: 100%;
  }
  .cookie-consent-bar h6 {
    font-size: 20px;
    line-height: 24px;
  }

  .cookie-consent-bar p {
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 10px;
  }

  .cookie-consent-bar .btn {
    font-size: 12px;
  }
  .modal-dialog {
    max-width: 90%;
    margin: 0 auto;
  }
  .whyapply .rec.rec-swipable {
    column-gap: 0px;
  }
  .selectbox {
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%;
    min-height: auto;
    margin: 0 auto 20px auto;
  }
  .whyapply .rec-carousel .rec-arrow {
    top: 41%;
  }
  .step-form-container
    .block-bar
    .formsteps
    .flexbar
    label.selectbox
    .primebox
    img {
    font-size: 40px;
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin-bottom: 10px;
  }
  .step-form-container
    .block-bar
    .formsteps
    .flexbar
    label.selectbox
    .primebox
    h5 {
    font-size: 18px;
    line-height: 24px;
  }
  .Otherarticle .articles-detail {
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
  .formrow .dflex-inputbar {
    padding: 0 0;
  }
  .contactformbar {
    padding: 50px 0;
  }
  .contact-banner .contact-head h6 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .contactformbar form .formrow .dflex-inputbar .dflex-bar {
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
  .contact-banner .contact-head h3 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 10px;
  }
  .applyjob-conditions h4 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .step-form-container.step-9 .formsteps,
  .step-form-container.step-10 .formsteps {
    max-width: 100%;
    margin: 0 auto;
  }
  .contact-banner-bottom ul.contactdetails li p {
    font-size: 16px;
    font-weight: 300;
  }
  .contact-banner-bottom ul.contactdetails li h4 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .contact-banner .contact-head {
    margin-bottom: 40px;
  }
  .contact-banner-bottom ul.contactdetails {
    grid-template-columns: auto auto auto;
    column-gap: 60px;
  }
  .joblistingbar ul .btnpitch {
    border-radius: 50px;
    padding: 6px 10px;
    font-size: 12px;
  }

  .joblistingbar ul li.btn-gps {
    margin-left: 0;
    font-size: 12px;
    column-gap: 3px;
  }
  .let-talk-block {
    padding: 0 0 50px 0;
  }
  .joblistingbar .jobtitle {
    text-align: center;
  }
  .joblistingbar ul {
    justify-content: center;
    gap: 20px;
  }
  .joblistingbar .btnjob {
    text-align: center;
  }
  .joblistingbar {
    padding: 30px 20px;
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
  .BlogDetailBanner .Categorybar {
    margin-bottom: 15px;
    text-align: center;
  }
  .BlogDetailBanner .Categorybar h5 {
    padding: 12px 30px;
    font-size: 12px;
  }
  .blogdetail-content .featured-img {
    margin-bottom: 90px;
  }
  .blogdetail-wrapper .featured-img {
    height: 220px;
    margin: 30px 0 30px 0;
  }
  .articles-block .row {
    gap: 30px;
  }
  .articles-block h3 {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 40px;
    text-align: center;
  }
  .articles-block .container {
    padding-top: 50px;
  }
  .articles-block {
    padding: 20px 0 50px 0;
    position: relative;
  }
  .blogdetail-wrapper h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 20px;
  }
  .spacer-50 {
    padding: 15px;
  }
  .blogdetail-wrapper p,
  .blogdetail-wrapper ul li {
    color: rgba(255, 255, 255, 0.8);
    font-family: Graphik;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 20px;
  }
  .allblogs {
    padding: 0px 0 100px 0;
  }
  .Articlebloglisting.bloglisting {
    margin: 0;
  }
  .bloglisting .blog-title {
    order: 2;
  }
  .listingbar {
    padding: 10px 0;
  }
  .listingbar .topbar-title {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
  .bloglisting {
    gap: 20px;
    grid-template-columns: auto;
  }

  .contactus {
    padding: 70px 0;
  }

  .contactus .title-contact h3 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .contactus .row {
    align-items: center;
    gap: 0px;
  }
  .listingbar.bg-gray {
    padding: 20px 15px;
  }
  .listingbar .topbar-title img {
    width: 50px;
  }
  .listingbar .topbar-title {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
  .listing-ul li,
  .content-p {
    font-size: 14px;
    line-height: 20px;
  }
  .listingbar .topbar-title h6 {
    margin: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
  }
  .Aboutbar .whyapply {
    padding: 0px 0 40px 0;
  }
  .aboutcontent .about-rytside .content-p {
    margin-bottom: 20px;
    font-size: 14px;
  }
  .aboutleft-img {
    height: 200px;
    overflow: hidden;
    width: 100%;
    margin-bottom: 30px;
  }
  .aboutbanner,
  .BlogBanner {
    padding: 120px 0 80px 0;
  }

  .aboutbanner h2,
  .BlogBanner .blog-head h2 {
    font-size: 32px;
    margin-bottom: 10px;
  }
  .aboutbanner .bar-line {
    margin-bottom: 10px;
  }
  .aboutbanner p {
    font-size: 14px;
    line-height: 22px;
  }
  .Copyrightbar {
    padding: 20px 0 20px 0;
  }
  .footernavbar {
    display: flex;
    flex-wrap: wrap;
    column-gap: 26px;
  }

  .footernavbar li {
    max-width: 100%;
    text-align: center;
    margin: 0 0 10px 0;
  }
  .copyryt-left {
    justify-content: center;
  }
  .social-icon {
    justify-content: center;
    margin-top: 20px;
  }
  .darkmode-modal .modal-content .modal-body .userrole-detail-modal .btngroup {
    grid-template-columns: auto;
  }
  .step-form-container .block-bar {
    padding: 50px 20px;
    background-size: 800px;
  }
  .let-talk-block .letstalk-inner {
    padding: 40px 20px !important;
    text-align: center;
  }
  .let-talk-block .letstalk-inner h3 {
    font-size: 20px;
    line-height: 24px;
  }
  .step-form-container .block-bar .formsteps .flexbar .mob-none {
    display: none;
  }
  .modalcontact .modal-content .modal-header .modal-title.h4 {
    font-size: 16px;
  }
  .step-form-container.step-9 .formsteps,
  .step-form-container.step-4 .formsteps,
  .step-form-container.step-5 .formsteps {
    max-width: 100%;
    margin: 0 auto;
  }
  .quick-block {
    padding: 50px 15px 0px 15px;
  }
  .step-form-container .block-bar .formsteps .flexbar {
    grid-template-columns: 1fr !important;
    column-gap: 15px;
  }
  .selectbox .primebox {
    padding: 15px 20px;
    font-size: 14px;
    border-radius: 0;
  }
  .selectbox {
    margin: 0 auto 10px auto;
  }
  .formsteps h4 {
    font-size: 18px;
  }
  .whyapply .rec-carousel-item .rec-item-wrapper .slidebox img {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }
  .lookingservice .carside img {
    width: 50px;
  }
  .formrow {
    margin: 0;
    display: block;
  }
  .formrow .form-50 {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0;
  }
  .LookingForTabs .LookingFortabs-block {
    margin-bottom: 30px;
  }
  .CareerOpportunitydivider .container h4 {
    font-size: 22px;
    text-align: center;
  }
  .CareerOpportunitydivider {
    padding: 40px 0;
  }
  .gridbar {
    grid-template-columns: auto;
    gap: 20px;
  }
  .primary-title h4 {
    font-size: 12px;
  }
  .primary-title h2 {
    font-size: 22px;
    line-height: 28px;
  }
  .LookingFor {
    padding: 40px 0;
  }
  .LookingFortabs-block li .nav-link {
    padding: 12px 30px;
    min-width: auto;
    width: 100%;
  }
  .LookingForTabs .LookingFortabs-block li.nav-item {
    width: 100%;
    margin: 7px 0;
  }
  .Articlebloglisting {
    grid-template-columns: auto;
    gap: 20px;
    padding: 20px;
  }
  .footerbar .foot-logobar {
    text-align: left;
    margin-bottom: 30px;
  }
  .footerleft {
    margin-bottom: 30px;
    text-align: left;
  }
  .footernavbar li {
    max-width: 100%;
    text-align: left;
    margin: 0 0 10px 0;
  }
  .Copyrightbar .container {
    padding: 0;
  }
  .copyryt-left {
    display: block;
  }
  .linebarbox {
    margin-top: 30px;
  }
  .step-form-container .block-bar form .form-control {
    padding: 16px 16px !important;
  }
  .step-form-container .block-bar form .form-control::placeholder {
    font-size: 12px;
  }
  .uploadresumeblock .reumeupload-form .inputfile p {
    text-align: left;
  }
}

@media (max-width: 574px) {
  .whyapply .rec-carousel-item .rec-item-wrapper .slidebox p {
    font-size: 16px;
  }

  .whyapply .rec-carousel-item .rec-item-wrapper .slidebox h4 {
    font-size: 18.718px;
  }
  .auth-form form {
    padding: 20px;
  }
  .auth-form {
    padding: 50px 0;
  }
  .contactformbar h4 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 30px;
  }
  .contactformbar {
    padding: 40px 0;
  }
  .contact-banner-bottom ul.contactdetails {
    gap: 20px;
  }
  .contact-banner-bottom ul.contactdetails li {
    max-width: 100%;
    width: 100%;
    border: none;
  }
  .joblistingbar ul {
    justify-content: center;
    column-gap: 10px;
  }
  .allblogs {
    padding: 0px 0 40px 0;
  }
  .bloglisting {
    padding-bottom: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
  }
  .allblogs .blogtab li.nav-item {
    flex: 0 0 30%;
    max-width: 100%;
  }
  .blog-content h2 {
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
  }
  .allblogs .blogtab .nav-link {
    padding: 12px 20px;
    background: transparent !important;
    border: none !important;
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: auto;
  }
  ::-webkit-scrollbar {
    background-color: rgb(255 255 255 / 24%);
    height: 1px;
  }
  .allblogs .blogtab {
    margin-bottom: 50px;
    border: none;
    flex-wrap: nowrap;
    gap: 10px;
    overflow-x: scroll;
    padding-bottom: 50px;
  }
  .allblogs .blogtab .nav-link.disabled {
    width: 100%;
    text-align: center;
  }
  .allblogs .blogtab li.nav-item button {
    width: 100%;
  }
  .bloglisting .blog-title .title-tophead {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 15px;
  }
  .bloglisting .blog-title h5 {
    font-size: 20px;
    line-height: 24px;
  }
  .bloglisting .blog-title p {
    font-size: 14px;
    line-height: 18px;
  }
  .bloglisting .blog-title .blogbtn {
    margin-top: 20px;
  }
  .aboutbanner h2,
  .BlogBanner .blog-head h2 {
    font-size: 24px;
    margin-bottom: 30px;
    line-height: 28px;
  }
  .copyryt-left {
    flex-direction: column;
  }
  .social-icon {
    column-gap: 10px;
  }
  .KeyNumber .linebarbox {
    margin: 0px 0 0 0;
  }
  .KeyNumber {
    padding: 30px 0;
  }
  .whyapply {
    padding: 0 0 20px 0;
    overflow: hidden;
  }
  .btngroup {
    display: block;
  }
  .MainHeader .navbar-primary {
    padding: 15px 0;
  }
  .MainHeader.sticky-header .navbar-primary {
    padding: 10px 0;
  }
  .banner .btngroup .btn {
    min-width: 100%;
    margin: 0 0 15px 0;
  }
  .banner h1 span {
    display: inline;
  }
  .banner h1 {
    font-size: 22px;
    line-height: 28px;
  }
  .home-wrapper .homebar .banner {
    height: auto;
    padding: 100px 0 40px 0;
  }
  .banner p {
    font-size: 15px;
    line-height: 22px;
    color: rgb(255 255 255 / 80%);
  }
  .whyapply .rec-carousel-item .rec-item-wrapper .slidebox p {
    color: rgb(255 255 255 / 80%);
  }
  .let-talk-block .letstalk-inner h3 {
    margin-bottom: 20px;
  }
  .whyapply .rec-carousel-item .rec-item-wrapper .slidebox::after,
  .whyapply .rec-carousel-item .rec-item-wrapper .slidebox::before {
    width: 320px;
    height: 320px;
  }
  .whyapply .btngroup {
    margin-top: 50px;
    padding: 0 0px;
    display: flex;
  }
  .btn {
    padding: 10px 30px;
    max-width: max-content;
    font-size: 14px;
  }
  .aboutcontent .about-rytside .content-p {
    margin-bottom: 20px;
    font-size: 14px;
    text-align: center;
  }
  .contactus .letstalk-inner {
    text-align: center;
  }
  .whyapply .rec-carousel .rec-arrow.rec-arrow-left {
    padding-bottom: 0px;
    padding-right: 0px;
  }
  .formtitle {
    font-size: 16px !important;
    font-weight: 300 !important;
    margin: 0 0 10px 0;
    text-align: left;
  }
  .joblistingbar ul .btnpitch {
    border-radius: 50px;
    font-size: 12px;
    padding: 6px 10px;
  }
  .joblistingbar ul {
    column-gap: 8px;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .joblistingbar ul li.btn-gps svg {
    font-size: 20px;
    fill: rgb(255 255 255 / 70%);
  }
  .footernavbar li a {
    font-size: 17px;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.205px;
    font-weight: 600;
  }
  .whyapply .rec-carousel-item .rec {
    width: 100% !important;
  }
}
